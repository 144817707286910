export const TOPIC_TYPES = {
  WORK: { id: 1, label: "Práce" },
  PROJECT: { id: 2, label: "Praktický projekt" },
  INTERNSHIP: { id: 3, label: "Stáž" },
  SELECTIVE_PROJECT: { id: 4, label: "Výběrový projekt" },
  THESIS: { id: 5, label: "Závěrečná práce" },
};

export const STATS_PERIODS = {
  LAST_MONTH: { id: 1, label: "Poslední měsíc" },
  LAST_YEAR: { id: 2, label: "Poslední rok" },
  FULL_HISTORY: { id: 3, label: "Celkem" },
};

export const COMPANY_STATUSES = {
  ALL: { id: "all", label: "Všechny" },
  CONFIRMED: { id: "confirmed", label: "Schválené" },
  UNCONFIRMED: { id: "unconfirmed", label: "Čekající na schválení" },
};
