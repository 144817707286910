import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

export function StudentStatsBoxes(props) {
  const history = useHistory();

  const redirectTo = (path) => {
    history.push(path);
  };

  return (
    <Fragment>
      <div className="row col-12">
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="info-box"
            onClick={() => redirectTo("/student-topics")}
          >
            <span className="info-box-icon bg-green">
              {props.statsTotals?.availableTopicCount}
              <i className="ion ion-ios-gear-outline"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Dostupná témata</span>
              <span className="info-box-number">
                {props.statsTotals?.activeTopicCount}
                &nbsp;
                <small>aktivních vypsaných témat celkem</small>
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="info-box"
            onClick={() => redirectTo("/student-registrations")}
          >
            <span className="info-box-icon bg-red">
              {props.statsTotals?.studentActiveRegistrationCount}
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Moje aktivní přihlášky</span>
              <span className="info-box-number">
                {props.statsTotals?.studentTotalRegistrationCount}
                &nbsp;
                <small>mých přihlášek celkem</small>
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="info-box"
            onClick={() => redirectTo("/student-requests")}
          >
            <span className="info-box-icon bg-orange">
              {props.statsTotals?.studentActiveRequestCount}
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Moje aktivní poptávky</span>
              <span className="info-box-number">
                {props.statsTotals?.studentTotalRequestCount}
                &nbsp;
                <small>mých potávek celkem</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
