import React, { useState, useContext, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export const rowClasses = (row, rowIndex) => {
  if (row) {
    if (row && row.deleted === true) {
      return "text-muted";
      // } else if (row.confirmed === false) {
      //   return "text-warning";
    } else if (row.confirmed === true) {
      return "text-dark";
    }
  }

  return "";
};

export const confirmedColumnFormatter = (cell, row, rowIndex) => {
  return (
    <div className="grid-attributes text-warning text-center">
      {cell ? (
        <span className={row.deleted ? "text-muted" : "text-success"}>
          <FontAwesomeIcon icon="check" />
        </span>
      ) : (
        <FontAwesomeIcon icon="hourglass-half" title="Čeká na schválení" />
      )}
    </div>
  );
};

export const attributesColumnFormatter = (cell, row, rowIndex) => {
  return (
    <div className="grid-attributes text-center">
      {row.deleted ? (
        <FontAwesomeIcon icon="trash-alt" title="Smazaný záznam" />
      ) : null}
      {row.suspendedWhen ? (
        <FontAwesomeIcon icon="pause" title="Pozastavená platnost" />
      ) : null}
    </div>
  );
};

export const dateFormatter = (cell) => {
  if (!cell) {
    return "";
  }
  return (
    <span className="text-nowrap">{moment(cell).format("DD. MM. YYYY")}</span>
  );
};

export const creditsColumnFormatter = (cell, row, rowIndex) => {
  return (
    <div className="grid-attributes text-center">
      {row.creditsGiven ? (
        <FontAwesomeIcon
          icon="marker"
          title="Kredity uděleny"
          className="text-success"
        />
      ) : null}
      {row.creditsDenied ? (
        <FontAwesomeIcon
          icon="marker"
          title="Kredity zamítnuty"
          className="text-danger"
        />
      ) : null}
    </div>
  );
};
