import React, { useEffect, Fragment, useState, useContext } from "react";
import authService from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import {
  QueryParameterNames,
  LogoutActions,
  ApplicationPaths,
} from "./ApiAuthorizationConstants";
import ThemeContext from "../contexts/ThemeContext";
import UserContext from "../contexts/UserContext";

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export function Logout(props) {
  const userContext = useContext(UserContext);
  const themeContext = useContext(ThemeContext);

  useEffect(
    function effectFunction() {
      switch (props.action) {
        case LogoutActions.Logout:
          if (!!window.history.state.state.local) {
            themeContext.setLoadingStatus(true);
            themeContext.setSuccessMessage("Probíhá odhlašování");
            logout(getReturnUrl());
          } else {
            // This prevents regular links to <app>/authentication/logout from triggering a logout
            themeContext.setErrorMessage(
              "Odhlášení nebylo iniciováno ze stránky aplikace"
            );
          }
          break;
        case LogoutActions.LogoutCallback:
          themeContext.setLoadingStatus(true);
          themeContext.setSuccessMessage(
            "Probíhá zpracování odpovědi serveru na žádost o odhlášení"
          );
          processLogoutCallback();
          break;
        case LogoutActions.LoggedOut:
          themeContext.setSuccessMessage("Odhlášení proběhlo úspěšně");

          // smazání session cookie - nutné kvůli Google Chrome
          var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            var domain = domain || document.domain;
            var path = path || "/";
            document.cookie =
              ".AspNetCore.Identity.Application" +
              "=; expires=" +
              +new Date() +
              "; domain=" +
              domain +
              "; path=" +
              path;
          }

          // cookies.remove(".AspNetCore.Identity.Application");
          // window.location.href = "/";

          navigateToReturnUrl("/");
          break;
        default:
          themeContext.setErrorMessage(`Neplatná akce '${props.action}'`);
        //throw new Error(`Neplatná akce '${action}'`);
      }
    },
    [props.action]
  );

  const logout = async (returnUrl) => {
    //const isauthenticated = await authService.isAuthenticated();
    //await authService.isAuthenticated();
    if (userContext.isAuthenticated) {
      const result = await authService.signOut(returnUrl);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          themeContext.setErrorMessage(result.message);
          break;
        default:
          throw new Error("Neplatný stav autentizace");
      }
    }
    // else {
    //   setMessage("Odhlášení proběhlo úspěšně");
    // }
  };

  const processLogoutCallback = async () => {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error("Chybné přesměrování");
      case AuthenticationResultStatus.Success:
        navigateToReturnUrl(getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        themeContext.setErrorMessage(result.message);
        break;
      default:
        throw new Error("Neplatný stav autentizace");
    }
  };

  const getReturnUrl = (state) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        "Chybná URL adresa. Zpětná adresa musí mít stejný základ jako tato stránka."
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}${ApplicationPaths.LoggedOut}`
    );
  };

  const navigateToReturnUrl = (returnUrl) => {
    return window.location.replace(returnUrl);
  };

  return (
    <Fragment>
      {/* {!isReady ? (
        <div></div>
      ) : !!message ? (
        <div className="row col-12 text-center">{message}</div>
      ) : (
        <div className="row animated-text text-center">Probíhá odhlašování</div>
      )} */}
    </Fragment>
  );
}
