import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import useFetch from "../api-hooks/useFetch";
import {
  rowClasses,
  confirmedColumnFormatter,
  attributesColumnFormatter,
  dateFormatter,
} from "../common/GridUtils";
import UserContext from "../contexts/UserContext";
import { TopicDeleteModal } from "../topics/TopicDeleteModal";

export function CompanyTopics(props) {
  const fetchService = useFetch("api/company-topics");
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [topics, setTopics] = useState([]);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { SearchBar } = Search;

  useEffect(function effectFunction() {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    const response = await fetchService.get(props.companyId);
    if (Array.isArray(response)) {
      setTopics(response);
    }
  };

  const deleteTopic = (e, id) => {
    e.preventDefault();
    setCurrentRowId(id);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = async () => {
    setShowDeleteModal(false);
    await fetchTopics();
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/companies/${row.id}`}>
              <FontAwesomeIcon icon="edit" title="Editovat záznam" />
            </Link>
            <Link
              to={`/companies/delete/${row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                deleteTopic(e, row.id);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" title="Smazat záznam" />
            </Link>
          </Fragment>
        ) : null}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "topicTypeName",
      text: "Typ tématu",
      sort: true,
    },
    {
      dataField: "title",
      text: "Název",
      sort: true,
    },
    {
      dataField: "validTo",
      text: "Platí do",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "capacity",
      text: "Počet míst",
      sort: true,
    },
    {
      dataField: "registeredStudentCount",
      text: "Přihlášeno",
      sort: true,
    },
    {
      dataField: "confirmed",
      text: "Schváleno",
      sort: true,
      formatter: confirmedColumnFormatter,
    },
    {
      dataField: "attributes",
      text: "Atributy",
      hidden: !userContext.user?.isAdmin,
      formatter: attributesColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "title",
      order: "desc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/topics/${row.id}`);
    },
  };

  return (
    <Fragment>
      <ToolkitProvider keyField="id" data={topics} columns={gridColumns} search>
        {(toolkitprops) => (
          <div className="col-12">
            <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />
            <Link
              to="/company-topics/new"
              className="btn btn-primary mb-2 float-right"
            >
              <FontAwesomeIcon icon="plus" /> Nové téma
            </Link>

            <div className="card card-outline">
              <div className="card-header">
                <BootstrapTable
                  {...toolkitprops.baseProps}
                  keyField="id"
                  data={topics}
                  columns={gridColumns}
                  pagination={paginationFactory({ sizePerPage: 25 })}
                  rowEvents={rowEvents}
                  defaultSorted={defaultSorted}
                  rowClasses={rowClasses}
                  noDataIndication={
                    <div className="text-center">Žádné záznamy</div>
                  }
                  bordered={false}
                  striped
                  hover
                  classes="table-sm"
                />
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>

      <TopicDeleteModal
        isOpen={showDeleteModal}
        id={currentRowId}
        onClose={onDeleteModalClose}
        onDeleted={onDeleted}
      />
    </Fragment>
  );
}
