import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function NotFoundError() {
  return (
    <Fragment>
      <div className="d-flex flex-row align-items-center justify-content-center m-5">
        <h2>Chyba 404 - Stránka nenalezena</h2>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <Link to={`/`} className="btn btn-primary">
          <FontAwesomeIcon icon="home" />
          &nbsp; Zpět na úvod
        </Link>
      </div>
    </Fragment>
  );
}
