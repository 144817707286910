import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from "../api-hooks/useFetch";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { StudentRegistrationDeleteModal } from "./StudentRegistrationDeleteModal";
import {
  rowClasses,
  confirmedColumnFormatter,
  attributesColumnFormatter,
  dateFormatter,
  creditsColumnFormatter,
} from "../common/GridUtils";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import queryString from "query-string";
import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import UserContext from "../contexts/UserContext";

export function StudentRegistrations(props) {
  const fetchService = useFetch("api/student-registration");
  const userContext = useContext(UserContext);
  const history = useHistory();
  const params = queryString.parse(props.location.search);
  const queryOrder = params.order || "";
  const [studentRegistrations, setStudentRegistrations] = useState([]);
  const [
    filteredStudentRegistrations,
    setFilteredStudentRegistrations,
  ] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("0");
  const [currentRowUserId, setCurrentRowUserId] = useState(null);
  const [currentRowTopicId, setCurrentRowTopicId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showMyTopicsOnly, setShowMyTopicsOnly] = useState(
    !userContext.user?.isAdmin
  );
  const { SearchBar } = Search;

  useEffect(function effectFunction() {
    fetchStudentRegistrations();
  }, []);

  useEffect(
    function effectFunction2() {
      filterStudentRegistrations();
    },
    [selectedCompanyId, showDeleted, showMyTopicsOnly, studentRegistrations]
  );

  const fetchStudentRegistrations = async () => {
    const response = await fetchService.get();
    if (!response) {
      return;
    }
    if (Array.isArray(response.studentRegistrations)) {
      setStudentRegistrations(response.studentRegistrations);
    }
    if (Array.isArray(response.companies)) {
      setCompanies(response.companies);
    }
    setSelectedCompanyId(response.myCompanyId ?? "0");
  };

  const deleteStudentRegistration = (e, userId, topicId) => {
    e.preventDefault();
    setCurrentRowUserId(userId);
    setCurrentRowTopicId(topicId);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = async () => {
    setShowDeleteModal(false);
    await fetchStudentRegistrations();
  };

  const changeCompany = (companyId) => {
    setSelectedCompanyId(companyId);
  };

  const filterStudentRegistrations = () => {
    var visibleStudentRegistrations = studentRegistrations.filter(
      (t) => showDeleted || t.deleted === false
    );

    if (showMyTopicsOnly) {
      if (userContext.user?.isStudent) {
        visibleStudentRegistrations = visibleStudentRegistrations.filter(
          (t) => t.userId === userContext.user?.id
        );
      } else if (!userContext.user?.isCompanyUser) {
        visibleStudentRegistrations = visibleStudentRegistrations.filter(
          (t) => t.guarantorUserId === userContext.user?.id
        );
      }
    }

    if (selectedCompanyId !== "0") {
      visibleStudentRegistrations = visibleStudentRegistrations.filter(
        (t) => t.companyId === parseInt(selectedCompanyId)
      );
    }

    setFilteredStudentRegistrations(visibleStudentRegistrations);
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/student-registrations/${row.userId}/${row.topicId}`}>
              <FontAwesomeIcon icon="edit" title="Editovat záznam" />
            </Link>
            <Link
              to={`/student-registrations/delete/${row.userId}/${row.topicId}`}
              onClick={(e) => {
                e.stopPropagation();
                deleteStudentRegistration(e, row.userId, row.topicId);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" title="Smazat záznam" />
            </Link>
          </Fragment>
        ) : (
          <Link to={`/student-registrations/${row.userId}/${row.topicId}`}>
            <FontAwesomeIcon icon="eye" title="Prohlížet záznam" />
          </Link>
        )}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "topicType",
      text: "Typ tématu",
      sort: true,
    },
    {
      dataField: "topicTitle",
      text: "Název",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Firma",
      sort: true,
    },
    {
      dataField: "fullName",
      text: "Student",
      sort: true,
    },
    {
      dataField: "createdWhen",
      text: "Podáno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "confirmedAtCompany",
      text: "Schv. firmou",
      formatter: confirmedColumnFormatter,
    },
    {
      dataField: "confirmedAtUniversity",
      text: "Schv. UHK",
      sort: true,
      formatter: confirmedColumnFormatter,
    },
    {
      dataField: "attributes",
      text: "Atributy",
      hidden: !userContext.user?.isAdmin,
      formatter: attributesColumnFormatter,
    },
    {
      dataField: "deliveredWhen",
      text: "Předáno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "credits",
      text: "Kredity",
      sort: true,
      formatter: creditsColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    queryOrder
      ? {
          dataField: queryOrder,
          order: "asc",
        }
      : {
          dataField: "createdWhen",
          order: "desc",
        },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/student-registration-details/${row.rowId}`);
    },
  };

  return (
    <ToolkitProvider
      keyField="rowId"
      data={filteredStudentRegistrations}
      columns={gridColumns}
      search
    >
      {(toolkitprops) => (
        <Fragment>
          {userContext.user.isStudent ? (
            <h5 id="tabelLabel">Moje přihlášky</h5>
          ) : (
            <h5 id="tabelLabel">Přihlášky</h5>
          )}

          <hr />
          <div className="row">
            <div className="col-md-2">
              <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />
            </div>
            {!userContext.user.isCompanyUser ? (
              <div className="col-md-3">
                <div className="row">
                  <div className="col-4 pt-2 text-right">
                    <span>Firma</span>
                  </div>
                  <div className="col-8">
                    <AvForm>
                      <AvField
                        type="select"
                        name="company"
                        onChange={(e) => changeCompany(e.target.value)}
                        value={selectedCompanyId}
                      >
                        <option value={0} defaultValue={true}>
                          Vše
                        </option>
                        {companies.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          );
                        })}
                      </AvField>
                    </AvForm>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-md custom-control custom-checkbox pt-2 text-right">
              {userContext.user.isGuarantor ? (
                <Fragment>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showMyTopicsOnly"
                    name="showMyTopicsOnly"
                    checked={showMyTopicsOnly}
                    onChange={(e) => setShowMyTopicsOnly(e.target.checked)}
                  />
                  <label
                    className="custom-control-label font-weight-normal"
                    htmlFor="showMyTopicsOnly"
                  >
                    Pouze má témata
                  </label>
                </Fragment>
              ) : null}

              {userContext.user.isAdmin ? (
                <Fragment>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showDeleted"
                    name="showDeleted"
                    checked={showDeleted}
                    onChange={(e) => setShowDeleted(e.target.checked)}
                  />
                  <label
                    className="custom-control-label font-weight-normal"
                    htmlFor="showDeleted"
                  >
                    Zobrazit smazané
                  </label>
                </Fragment>
              ) : null}
            </div>
          </div>

          <div className="card card-outline">
            <div className="card-header">
              <BootstrapTable
                {...toolkitprops.baseProps}
                keyField="rowId"
                data={filteredStudentRegistrations}
                columns={gridColumns}
                pagination={paginationFactory({ sizePerPage: 25 })}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                rowClasses={rowClasses}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>

          <StudentRegistrationDeleteModal
            isOpen={showDeleteModal}
            userId={currentRowUserId}
            topicId={currentRowTopicId}
            onClose={onDeleteModalClose}
            onDeleted={onDeleted}
          />
        </Fragment>
      )}
    </ToolkitProvider>
  );
}
