import React, { Component, Fragment } from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (isAuthenticated) {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true },
      };
      return this.authenticatedView(userName, profilePath, logoutPath);
    } else {
      if (!isAuthenticated) {
        // const registerPath = `${ApplicationPaths.Register}`;
        const loginPath = `${ApplicationPaths.Login}`;
        return this.anonymousView(loginPath);
      } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = {
          pathname: `${ApplicationPaths.LogOut}`,
          state: { local: true },
        };
        return this.authenticatedView(userName, profilePath, logoutPath);
      }
    }
  }

  authenticatedView(userName, profilePath, logoutPath) {
    return (
      <Fragment>
        <UncontrolledDropdown>
          <DropdownToggle nav caret className="text-light nav-link">
            <FontAwesomeIcon icon="user" /> {userName}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <Link to={logoutPath}>
                <FontAwesomeIcon icon="sign-out-alt" /> Odhlášení
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to={profilePath}>
                <FontAwesomeIcon icon="cog" /> Správa účtu
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    );
  }

  anonymousView(loginPath) {
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} className="text-light" to={loginPath}>
            <FontAwesomeIcon icon="user" /> Přihlášení
          </NavLink>
        </NavItem>
      </Fragment>
    );
  }
}
