import React, { useState, useEffect, useContext, Fragment } from "react";
import { Button, Label } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { BackButton } from "../common/BackButton";
import ThemeContext from "../contexts/ThemeContext";
import UserContext from "../contexts/UserContext";
import useFetch from "../api-hooks/useFetch";
import { AuditInfo } from "../common/AuditInfo";
import { DeletedInfo } from "../common/DeletedInfo";
import { CompanyDeleteModal } from "./CompanyDeleteModal";
import { useHistory } from "react-router-dom";
import { CompanySuspendModal } from "./CompanySuspendModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export function CompanyForm(props) {
  const fetchService = useFetch("api/company");
  const history = useHistory();
  const [companyId, setCompanyId] = useState(null);
  const [company, setCompany] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [name, setName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [town, setTown] = useState("");
  const [postcode, setPostcode] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSuspendModalOpen, setSuspendModalOpen] = useState(false);
  const [suspendedWhen, setSuspendedWhen] = useState(null);
  const [suspendedBy, setSuspendedBy] = useState("");

  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  useEffect(function effectFunction() {
    if (props.companyId) {
      setCompanyId(parseInt(props.companyId));
    } else if (props.match.params.id) {
      setCompanyId(parseInt(props.match.params.id));
    }
  }, []);

  useEffect(
    function effectFunction() {
      async function fetchCompany() {
        if (companyId) {
          const data = await fetchService.get(companyId);
          setCompanyData(data);
        }
      }
      fetchCompany();
    },
    [companyId]
  );

  const setCompanyData = (data) => {
    if (data) {
      setCompany(data);
      setIdentificationNumber(data.identificationNumber);
      setName(data.name);
      setStreetAddress(data.streetAddress);
      setTown(data.town);
      setPostcode(data.postcode);
      setConfirmed(data.confirmed);
      setDeleted(data.deleted);
      setCanEdit(userContext.user && userContext.user.isAdmin);
      setCanDelete(userContext.user && userContext.user.isAdmin);
      setSuspendedWhen(data.suspendedWhen);
      setSuspendedBy(data.suspendedBy);
    }
  };

  const updateCompany = async (e) => {
    e.preventDefault();
    const body = {
      id: companyId,
      identificationNumber,
      name,
      streetAddress,
      town,
      postcode,
      confirmed,
    };

    const data = await fetchService.put(companyId, body);
    if (data) {
      setCompanyData(data);
      setTimeout(function () {
        history.push("/companies");
      }, 3000);
    }
  };

  const findCompany = async () => {
    const aresData = await fetchService.get(`ares/${identificationNumber}`);
    if (aresData) {
      themeContext.setSuccessMessage("Firma úspěšně nalezena.");
      setName(aresData.name);
      setStreetAddress(aresData.streetAddress);
      setTown(aresData.town);
      setPostcode(aresData.postcode);
    }
  };

  const confirmCompany = async () => {
    const data = await fetchService.put(`confirm/${parseInt(companyId)}`, {});
    setCompanyData(data);
  };

  const cancelCompanySuspension = async () => {
    const data = await fetchService.put(
      `cancel-suspension/${companyId}`,
      {},
      "Platnost firmy byla úspěšně obnovena"
    );
    setCompanyData(data);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const openSuspendModal = () => {
    setSuspendModalOpen(true);
  };

  const onSuspendModalClose = () => {
    setSuspendModalOpen(false);
  };

  const resetCompanyData = (companyData) => {
    setCompanyData(companyData);
  };

  const backToList = () => {
    history.push(`/companies`);
  };

  return (
    <div className="card card-outline form-container">
      <div className="card-header">
        <DeletedInfo deleted={deleted} />

        {!confirmed && company ? (
          <div className="alert alert-warning" role="alert">
            Firma není schválena.
            {!deleted &&
            userContext.user &&
            (userContext.user.isAdmin || userContext.user.isGuarantor) ? (
              <Button
                className="btn btn-primary float-right"
                onClick={confirmCompany}
              >
                Schválit
              </Button>
            ) : null}
          </div>
        ) : null}

        {suspendedWhen && company ? (
          <div className="alert alert-warning" role="alert">
            Platnost firmy byla pozastavena{" "}
            {moment(suspendedWhen).format("D. M. YYYY HH:MM")} uživatelem{" "}
            {suspendedBy}.
            {!deleted &&
            userContext.user &&
            (userContext.user.isAdmin || userContext.user.isGuarantor) ? (
              <Button
                className="btn btn-primary float-right"
                onClick={cancelCompanySuspension}
              >
                Obnovit platnost
              </Button>
            ) : null}
          </div>
        ) : null}

        <AvForm onValidSubmit={updateCompany}>
          <div className="row no-gutters">
            <AvGroup className="col-3 mb-1">
              <Label htmlFor="identificationNumber">IČO</Label>
              <AvField
                type="number"
                name="identificationNumber"
                disabled={deleted || !canEdit}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "IČO je povinná položka",
                  },
                  pattern: {
                    value: "^[0-9]+$",
                    errorMessage: "Povolené jsou číslice",
                  },
                  minLength: {
                    value: 8,
                    errorMessage: "Minimální délka je 8 znaků",
                  },
                  maxLength: {
                    value: 8,
                    errorMessage: "Maximální délka je 8 znaků",
                  },
                }}
                onChange={(e) => setIdentificationNumber(e.target.value)}
                value={identificationNumber}
              />
            </AvGroup>
            <AvGroup className="col-3 mb-1">
              <Label htmlFor="aresButton" className="col-12">
                &nbsp;
              </Label>
              <Button
                className="btn btn-primary ml-3"
                type="button"
                name="aresButton"
                disabled={
                  deleted ||
                  !canEdit ||
                  !identificationNumber ||
                  identificationNumber.length !== 8
                }
                onClick={findCompany}
              >
                Vyhledat v ARESu
              </Button>
            </AvGroup>
          </div>
          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-1">
              <Label htmlFor="name">Název společnosti</Label>
              <AvField
                type="text"
                name="name"
                disabled={deleted || !canEdit}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Název společnosti je povinná položka",
                  },
                }}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </AvGroup>
            <AvGroup className="col-6 pl-2 mb-1">
              <Label htmlFor="streetAddress">Ulice a č.p.</Label>
              <AvField
                type="text"
                name="streetAddress"
                disabled={deleted || !canEdit}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Ulice a č.p. je povinná položka",
                  },
                }}
                onChange={(e) => setStreetAddress(e.target.value)}
                value={streetAddress}
              />
            </AvGroup>
          </div>
          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-1">
              <Label htmlFor="town">Obec</Label>
              <AvField
                type="text"
                name="town"
                disabled={deleted || !canEdit}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Obec je povinná položka",
                  },
                }}
                onChange={(e) => setTown(e.target.value)}
                value={town}
              />
            </AvGroup>
            <AvGroup className="col-3 pl-2 mb-1">
              <Label htmlFor="postcode">PSČ</Label>
              <AvField
                type="text"
                name="postcode"
                disabled={deleted || !canEdit}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "PSČ je povinná položka",
                  },
                }}
                onChange={(e) => setPostcode(e.target.value)}
                value={postcode}
              />
            </AvGroup>
          </div>
          <div className="row no-gutters mb-3">
            {confirmed ? (
              <Fragment>
                <span className={deleted ? "text-muted" : "text-success"}>
                  <FontAwesomeIcon icon="check" />
                </span>
                &nbsp;Schválená firma
              </Fragment>
            ) : company && !confirmed ? (
              <span className={deleted ? "text-muted" : "text-warning"}>
                <FontAwesomeIcon icon="times" /> Neschválená firma
              </span>
            ) : null}
          </div>

          {!deleted ? (
            <Fragment>
              {canEdit ? (
                <Button className="btn btn-primary mr-2">Uložit</Button>
              ) : null}

              {canEdit && !suspendedWhen ? (
                <Button
                  className="btn btn-danger mr-2"
                  onClick={openSuspendModal}
                >
                  Pozastavit
                </Button>
              ) : null}

              {canDelete ? (
                <Button
                  className="btn btn-danger mr-2"
                  onClick={openDeleteModal}
                >
                  Smazat
                </Button>
              ) : null}
            </Fragment>
          ) : null}
          <BackButton />
        </AvForm>

        {/* {!isNew ? ( */}
        <CompanyDeleteModal
          isOpen={isDeleteModalOpen}
          id={companyId}
          onClose={onDeleteModalClose}
          onDeleted={backToList}
        />
        {/* ) : null} */}

        {/* {!isNew ? ( */}
        <CompanySuspendModal
          isOpen={isSuspendModalOpen}
          id={companyId}
          onClose={onSuspendModalClose}
          onSuspended={resetCompanyData}
        />
        {/* ) : null} */}

        <AuditInfo auditData={company} />
      </div>
    </div>
  );
}
