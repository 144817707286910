import React, { useState, useEffect, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { rowClasses } from "../common/GridUtils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

export function StudentTopicsGrid(props) {
  const [topics, setTopics] = useState([]);
  const [topicTypeId, setTopicTypeId] = useState(0);
  const { SearchBar } = Search;

  useEffect(() => {
    setTopics(props.topics);
    setTopicTypeId(props.typeId);
  }, [props.topics, props.typeId]);

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/student-topics/${row.id}`}>
              <FontAwesomeIcon icon="eye" title="Prohlížet záznam" />
            </Link>
          </Fragment>
        ) : null}
      </div>
    );
  };

  const occupancyColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {row.occupancy}
        {row.currentUserSignedInWhen ? (
          <Fragment>
            &nbsp;&nbsp;
            <FontAwesomeIcon
              icon="check-circle"
              className="text-success"
              title={`Přihlášen dne ${new Date(
                row.currentUserSignedInWhen
              ).toLocaleDateString()}`}
            />
          </Fragment>
        ) : null}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "title",
      text: "Název",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Firma",
      sort: true,
    },
    {
      dataField: "validTo",
      text: "Platí do",
      sort: true,
    },
    {
      dataField: "occupancy",
      text: "Obsazenost",
      formatter: occupancyColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "title",
      order: "desc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/student-topics/${row.id}`);
    },
  };

  return (
    <ToolkitProvider keyField="id" data={topics} columns={gridColumns} search>
      {(toolkitprops) => (
        <div className="col-12">
          <SearchBar
            id={`searchbar-${topicTypeId}`}
            placeholder={"Hledat"}
            {...toolkitprops.searchProps}
          />

          <div className="card card-outline">
            <div className="card-header">
              <BootstrapTable
                {...toolkitprops.baseProps}
                pagination={paginationFactory({ sizePerPage: 25 })}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                rowClasses={rowClasses}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
}
