import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { AdminChart } from "../admin/AdminChart";
import useFetch from "../api-hooks/useFetch";
import { CompanyStatsBoxes } from "./CompanyStatsBoxes";
import { STATS_PERIODS } from "../common/Constants";

export function CompanyStatsTabs() {
  const fetchService = useFetch("api/company-dashboard");
  const [activeTab, setActiveTab] = useState(STATS_PERIODS.LAST_MONTH.id);
  const [lastMonthStats, setLastMonthStats] = useState(null);
  const [lastYearStats, setLastYearStats] = useState(null);
  const [fullHistoryStats, setFullHistoryStats] = useState(null);

  useEffect(() => {
    fetchStats(STATS_PERIODS.LAST_MONTH.id);
  }, [fetchStats]);

  async function fetchStats(activeTabId) {
    switch (activeTabId) {
      case STATS_PERIODS.LAST_MONTH.id:
        if (!lastMonthStats) {
          const stats = await fetchService.get(STATS_PERIODS.LAST_MONTH.id);
          if (stats?.totals) {
            setLastMonthStats(stats);
          }
        }
        break;
      case STATS_PERIODS.LAST_YEAR.id:
        if (!lastYearStats) {
          const stats = await fetchService.get(STATS_PERIODS.LAST_YEAR.id);
          if (stats?.totals) {
            setLastYearStats(stats);
          }
        }
        break;
      case STATS_PERIODS.FULL_HISTORY.id:
        if (!fullHistoryStats) {
          const stats = await fetchService.get(STATS_PERIODS.FULL_HISTORY.id);
          if (stats?.totals) {
            setFullHistoryStats(stats);
          }
        }
        break;
      default:
        break;
    }
  }

  const toggle = async (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    await fetchStats(tab);
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === STATS_PERIODS.LAST_MONTH.id,
            })}
            onClick={() => {
              toggle(STATS_PERIODS.LAST_MONTH.id);
            }}
          >
            <h5 id="tabelLabel">{STATS_PERIODS.LAST_MONTH.label}</h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === STATS_PERIODS.LAST_YEAR.id,
            })}
            onClick={() => {
              toggle(STATS_PERIODS.LAST_YEAR.id);
            }}
          >
            <h5 id="tabelLabel">{STATS_PERIODS.LAST_YEAR.label}</h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === STATS_PERIODS.FULL_HISTORY.id,
            })}
            onClick={() => {
              toggle(STATS_PERIODS.FULL_HISTORY.id);
            }}
          >
            <h5 id="tabelLabel">{STATS_PERIODS.FULL_HISTORY.label}</h5>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={STATS_PERIODS.LAST_MONTH.id}>
          <Row className="mt-3">
            <CompanyStatsBoxes statsData={lastMonthStats} />
            <AdminChart chartData={lastMonthStats?.lineChartData} />
          </Row>
        </TabPane>
        <TabPane tabId={STATS_PERIODS.LAST_YEAR.id}>
          <Row className="mt-3">
            <CompanyStatsBoxes statsData={lastYearStats} />
            <AdminChart chartData={lastYearStats?.lineChartData} />
          </Row>
        </TabPane>
        <TabPane tabId={STATS_PERIODS.FULL_HISTORY.id}>
          <Row className="mt-3">
            <CompanyStatsBoxes statsData={fullHistoryStats} />
            <AdminChart chartData={fullHistoryStats?.lineChartData} />
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
