import React, { useState, useEffect, Fragment, useContext } from "react";
import useFetch from "../api-hooks/useFetch";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Button, Label } from "reactstrap";
import { BackButton } from "../common/BackButton";
import { StudentRequestDeleteModal } from "./StudentRequestDeleteModal";
import UserContext from "../contexts/UserContext";

export function StudentRequestForm(props) {
  const fetchService = useFetch("api/student-request");
  const userContext = useContext(UserContext);
  const [studentRequest, setStudentRequest] = useState("");
  const [isNew, setIsNew] = useState(true);
  const [topicTypes, setTopicTypes] = useState([]);
  const [title, setTitle] = useState("");
  const [topicTypeId, setTopicTypeId] = useState("");
  const [description, setDescription] = useState("");
  const [validTo, setValidTo] = useState("");
  const [createdWhen, setCreatedWhen] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(function effectFunction() {
    const newStudentRequest = props.match.params.id === "new";
    setIsNew(newStudentRequest);
    async function fetchStudentRequest() {
      const data = await fetchService.get(props.match.params.id);
      if (data) {
        if (isNew && Array.isArray(data.topicTypes)) {
          setTopicTypes(data.topicTypes);
        }
        if (data.id) {
          setStudentRequestData(data);
        }
      }
    }
    fetchStudentRequest();
  }, []);

  const setStudentRequestData = (request) => {
    setStudentRequest(request);
    setTitle(request.title);
    setTopicTypeId(request.topicTypeId);
    setDescription(request.description);
    setValidTo(request.validTo.substr(0, 10));
    setCreatedWhen(request.createdWhen.substr(0, 10));
  };

  const onTopicTypeChange = (e) => {
    const fieldValue = e.target.value;
    setTopicTypeId(fieldValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      topicTypeId,
      title,
      description,
      validTo,
    };
    if (isNew) {
      await fetchService.post(body);
      props.history.push(`/student-requests`);
    } else {
      await fetchService.put(props.topicId, body);
    }
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const backToList = () => {
    props.history.push(`/student-requests`);
  };

  return (
    <div className="card card-outline form-container">
      <div className="card-header">
        <h5 id="tabelLabel">Detail poptávky</h5>
        <hr />
        <AvForm onValidSubmit={handleSubmit}>
          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-1">
              <Label htmlFor="topicTypeId">Typ poptávky:</Label>
              {isNew ? (
                <AvField
                  type="select"
                  name="topicTypeId"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Typ poptávky je povinná položka",
                    },
                  }}
                  onChange={onTopicTypeChange}
                  value={topicTypeId === null ? "" : topicTypeId}
                >
                  <option>Vyberte typ poptávky...</option>
                  {topicTypes.map((topicType) => {
                    return (
                      <option key={topicType.id} value={topicType.id}>
                        {topicType.name}
                      </option>
                    );
                  })}
                </AvField>
              ) : (
                <AvField
                  type="text"
                  name="topicTypeName"
                  value={studentRequest?.topicTypeName}
                  readOnly
                />
              )}
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-12 pr-2 mb-0">
              <Label htmlFor="title">Název</Label>
              <AvField
                type="text"
                name="title"
                value={title}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Název je povinná položka",
                  },
                }}
                readOnly={!isNew}
                onChange={(e) => setTitle(e.target.value)}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-12 pr-2 mb-0">
              <Label htmlFor="description">Popis</Label>
              <AvField
                type="textarea"
                name="description"
                value={description}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Popis je povinná položka",
                  },
                }}
                readOnly={!isNew}
                onChange={(e) => setDescription(e.target.value)}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            {!userContext.user?.isStudent ? (
              <AvGroup className="col-6 pr-2 mb-0">
                <Label htmlFor="studentEmail">Email studenta</Label>
                <AvField
                  type="text"
                  name="studentEmail"
                  value={studentRequest?.studentEmail}
                  readOnly
                />
              </AvGroup>
            ) : null}

            <AvGroup className="col-3 pr-2 mb-0">
              <Label htmlFor="validTo">Platí do</Label>
              <AvField
                type="date"
                name="validTo"
                value={validTo}
                readOnly={!isNew}
                onChange={(e) => setValidTo(e.target.value)}
              />
            </AvGroup>

            {!isNew ? (
              <AvGroup className="col-3 pr-2 mb-0">
                <Label htmlFor="createdWhen">Vytvořeno</Label>
                <AvField
                  type="date"
                  name="createdWhen"
                  value={createdWhen}
                  readOnly
                />
              </AvGroup>
            ) : null}
          </div>

          <div className="mb-2">
            {isNew ? (
              <Button className="btn btn-primary mr-2">Odeslat</Button>
            ) : (
              <Button className="btn btn-danger mr-2" onClick={openDeleteModal}>
                Smazat
              </Button>
            )}

            <BackButton />
          </div>
        </AvForm>

        {!isNew ? (
          <StudentRequestDeleteModal
            isOpen={isDeleteModalOpen}
            id={props.match.params.id}
            onClose={onDeleteModalClose}
            onDeleted={backToList}
          />
        ) : null}
      </div>
    </div>
  );
}
