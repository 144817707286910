import React from "react";
import { withRouter } from "react-router";

export class BackButtonBase extends React.Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-secondary"
        disabled={!this.props.history}
        onClick={() =>
          this.props.url
            ? this.props.history.push(this.props.url)
            : this.props.history.goBack()
        }
      >
        Zpět
      </button>
    );
  }
}

export const BackButton = withRouter(BackButtonBase);
