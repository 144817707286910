import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { TOPIC_TYPES } from "../common/Constants";
import queryString from "query-string";
import { StudentTopicsGrid } from "./StudentTopicsGrid";
import useFetch from "../api-hooks/useFetch";

export function StudentTopicsTabs(props) {
  const fetchService = useFetch("api/student-topic");
  const [activeTab, setActiveTab] = useState(1);
  const [topicsWork, setTopicsWork] = useState([]);
  const [topicsProject, setTopicsProject] = useState([]);
  const [topicsInternship, setTopicsInternship] = useState([]);
  const [topicsSelectiveProject, setTopicsSelectiveProject] = useState([]);

  useEffect(() => {
    let params = queryString.parse(props.location.search);
    setActiveTab(parseInt(params.tab) || 1);

    async function fetchTopics() {
      const response = await fetchService.get();
      if (!response) {
        return;
      }
      if (Array.isArray(response.topics)) {
        divideTopicsByType(response.topics);
      }
    }

    if (props.topics) {
      divideTopicsByType(props.topics);
    } else {
      fetchTopics();
    }
  }, []);

  const divideTopicsByType = (topics) => {
    setTopicsWork(getTopicsByType(topics, 1));
    setTopicsProject(getTopicsByType(topics, 2));
    setTopicsInternship(getTopicsByType(topics, 3));
    setTopicsSelectiveProject(getTopicsByType(topics, 4));
  };

  const getTopicsByType = (topics, typeId) => {
    if (!topics) {
      return [];
    }

    var topicsOfType = topics
      .filter((t) => t.topicTypeId === typeId)
      .map((t) => {
        return t;
      });

    return topicsOfType;
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <h5 id="tabelLabel">Aktuální nabídky</h5>
      <hr />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TOPIC_TYPES.WORK.id,
            })}
            onClick={() => {
              toggle(TOPIC_TYPES.WORK.id);
            }}
          >
            <h5 id="tabelLabel">
              {TOPIC_TYPES.WORK.label} ({topicsWork.length})
            </h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TOPIC_TYPES.PROJECT.id,
            })}
            onClick={() => {
              toggle(TOPIC_TYPES.PROJECT.id);
            }}
          >
            <h5 id="tabelLabel">
              {TOPIC_TYPES.PROJECT.label} ({topicsProject.length})
            </h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TOPIC_TYPES.INTERNSHIP.id,
            })}
            onClick={() => {
              toggle(TOPIC_TYPES.INTERNSHIP.id);
            }}
          >
            <h5 id="tabelLabel">
              {TOPIC_TYPES.INTERNSHIP.label} ({topicsInternship.length})
            </h5>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TOPIC_TYPES.SELECTIVE_PROJECT.id,
            })}
            onClick={() => {
              toggle(TOPIC_TYPES.SELECTIVE_PROJECT.id);
            }}
          >
            <h5 id="tabelLabel">
              {TOPIC_TYPES.SELECTIVE_PROJECT.label} (
              {topicsSelectiveProject.length})
            </h5>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={TOPIC_TYPES.WORK.id}>
          <Row className="mt-3">
            <StudentTopicsGrid
              topics={topicsWork}
              typeId={TOPIC_TYPES.WORK.id}
              history={props.history}
            />
          </Row>
        </TabPane>
        <TabPane tabId={TOPIC_TYPES.PROJECT.id}>
          <Row className="mt-3">
            <StudentTopicsGrid
              topics={topicsProject}
              typeId={TOPIC_TYPES.PROJECT.id}
              history={props.history}
            />
          </Row>
        </TabPane>
        <TabPane tabId={TOPIC_TYPES.INTERNSHIP.id}>
          <Row className="mt-3">
            <StudentTopicsGrid
              topics={topicsInternship}
              typeId={TOPIC_TYPES.INTERNSHIP.id}
              history={props.history}
            />
          </Row>
        </TabPane>
        <TabPane tabId={TOPIC_TYPES.SELECTIVE_PROJECT.id}>
          <Row className="mt-3">
            <StudentTopicsGrid
              topics={topicsSelectiveProject}
              typeId={TOPIC_TYPES.SELECTIVE_PROJECT.id}
              history={props.history}
            />
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
