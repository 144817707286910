import React, { Fragment } from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Line,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import "moment/locale/cs";

export function AdminChart(props) {
  return (
    <Fragment>
      {props.chartData?.dataPoints ? (
        <div className="row col-12 justify-content-center">
          <ResponsiveContainer width="90%" aspect={4.0 / 2.0}>
            <LineChart
              data={props.chartData?.dataPoints}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="dateTimeTicks"
                tickFormatter={(timeStr) =>
                  moment(timeStr).lang("cs").format("LL")
                }
              />
              <YAxis
                label={{
                  value: "Počet",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                name="Nová témata"
                type="monotone"
                dataKey="topicCount"
                stroke="#00aff0"
                strokeWidth="3"
              />
              <Line
                name="Nové firmy"
                type="monotone"
                dataKey="companyCount"
                stroke="#ffa300"
                strokeWidth="3"
              />
              <Line
                name="Přihlášení studenti"
                type="monotone"
                dataKey="topicStudentCount"
                stroke="#28a745"
                strokeWidth="3"
              />
              <Line
                name="Dokončená témata"
                type="monotone"
                dataKey="finishedTopicCount"
                stroke="#ce0058"
                strokeWidth="3"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : null}
    </Fragment>
  );
}
