import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import useFetch from "../api-hooks/useFetch";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { toBase64 } from "../common/FileUtils";

export function DeliveryModal(props) {
  const fetchService = useFetch("api/student-registration");
  const [isDeliveryModalOpen, setDeliveryModalOpen] = useState(false);
  const [fileUploadVisible, setFileUploadVisible] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    setDeliveryModalOpen(props.isOpen);
  }, [props.isOpen]);

  const toggleModal = () => setDeliveryModalOpen(!isDeliveryModalOpen);

  const confirmDelivery = async (e) => {
    e.preventDefault();

    var base64file = await toBase64(selectedFile);

    const registrationData = await fetchService.put(
      `confirm-delivery/${props.userId}/${props.topicId}`,
      { base64file, originalFileName: selectedFile.name },
      "Téma bylo úspěšně označeno jako předané"
    );

    props.onDelivered(registrationData);

    toggleModal();
  };

  const closeModal = () => {
    toggleModal();
    props.onClose();
  };

  const onSelectedFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Fragment>
      <Modal
        isOpen={isDeliveryModalOpen}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <AvForm onValidSubmit={confirmDelivery}>
          <ModalHeader toggle={toggleModal}>Předání tématu</ModalHeader>
          <ModalBody>
            <div>Opravdu chcete potvrdit předání zpracovaného tématu?</div>

            {fileUploadVisible ? (
              <div className="pt-3">
                <div>
                  <Label htmlFor="fileUpload">Závěrečná zpráva</Label>
                </div>
                <input
                  type="file"
                  id="fileUpload"
                  onChange={onSelectedFileChange}
                />
                &nbsp;
              </div>
            ) : null}

            <div className="custom-control custom-checkbox pt-3">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showDeleted"
                name="showDeleted"
                //checked={showDeleted}
                onChange={(e) => setFileUploadVisible(!e.target.checked)}
              />
              <label
                className="custom-control-label font-weight-normal"
                htmlFor="showDeleted"
              >
                Předat bez vložení závěrečné zprávy
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-primary" type="submit">
              Ano
            </Button>{" "}
            <Button
              className="btn btn-secondary"
              type="button"
              onClick={closeModal}
            >
              Ne
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
}
