import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from "../api-hooks/useFetch";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { TopicDeleteModal } from "./TopicDeleteModal";
import {
  rowClasses,
  confirmedColumnFormatter,
  attributesColumnFormatter,
  dateFormatter,
} from "../common/GridUtils";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import queryString from "query-string";
import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import UserContext from "../contexts/UserContext";

export function Topics(props) {
  const fetchService = useFetch("api/topic");
  const userContext = useContext(UserContext);
  const params = queryString.parse(props.location.search);
  const queryOrder = params.order || "";
  const [topics, setTopics] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [topicTypes, setTopicTypes] = useState([]);
  const [selectedTopicTypeId, setSelectedTopicTypeId] = useState("0");
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("0");
  const [myCompanyId, setMyCompanyId] = useState("0");
  const [currentRowId, setCurrentRowId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showMyTopicsOnly, setShowMyTopicsOnly] = useState(
    !userContext.user?.isAdmin &&
      !userContext.user?.isCompanyManager &&
      !userContext.user?.isCompanyEmployee
  );

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  useEffect(function effectFunction() {
    fetchTopics();
  }, []);

  useEffect(
    function effectFunction2() {
      filterTopics();
    },
    [
      selectedTopicTypeId,
      selectedCompanyId,
      showDeleted,
      showMyTopicsOnly,
      topics,
    ]
  );

  const fetchTopics = async () => {
    const response = await fetchService.get();
    if (!response) {
      return;
    }
    setMyCompanyId(response.myCompanyId ?? "0");
    if (Array.isArray(response.topics)) {
      setTopics(response.topics);
    }
    if (Array.isArray(response.topicTypes)) {
      setTopicTypes(response.topicTypes);
    }
    if (Array.isArray(response.companies)) {
      setCompanies(response.companies);
    }
    setSelectedCompanyId(response.myCompanyId ?? "0");
  };

  const copyTopic = (e, id) => {
    e.preventDefault();
    props.history.push(`/topics/new/${id}`);
  };

  const deleteTopic = (e, id) => {
    e.preventDefault();
    setCurrentRowId(id);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = async () => {
    setShowDeleteModal(false);
    await fetchTopics();
  };

  const changeTopicType = (topicTypeId) => {
    setSelectedTopicTypeId(topicTypeId);
  };

  const changeCompany = (companyId) => {
    setSelectedCompanyId(companyId);
  };

  const filterTopics = () => {
    var visibleTopics = topics.filter(
      (t) => showDeleted || t.deleted === false
    );

    if (showMyTopicsOnly) {
      visibleTopics = visibleTopics.filter(
        (t) => t.guarantorUserId === userContext.user?.id
      );
    }

    if (selectedTopicTypeId !== "0") {
      visibleTopics = visibleTopics.filter(
        (t) => t.topicTypeId === parseInt(selectedTopicTypeId)
      );
    }

    if (selectedCompanyId !== "0") {
      visibleTopics = visibleTopics.filter(
        (t) => t.companyId === parseInt(selectedCompanyId)
      );
    }

    setFilteredTopics(visibleTopics);
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/topics/${row.id}`}>
              <FontAwesomeIcon icon="edit" title="Editovat záznam" />
            </Link>
            {userContext.user?.isAdmin ||
            userContext.user?.isGuarantor ||
            row.companyId === myCompanyId ? (
              <Link
                to={`/topics/new/${row.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  copyTopic(e, row.id);
                }}
              >
                <FontAwesomeIcon icon="copy" title="Kopírovat záznam" />
              </Link>
            ) : null}
            <Link
              to={`/topics/delete/${row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                deleteTopic(e, row.id);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" title="Smazat záznam" />
            </Link>
          </Fragment>
        ) : (
          <Link to={`/topics/${row.id}`}>
            <FontAwesomeIcon icon="eye" title="Prohlížet záznam" />
          </Link>
        )}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "topicTypeName",
      text: "Typ tématu",
      sort: true,
    },
    {
      dataField: "title",
      text: "Název",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Firma",
      sort: true,
    },
    {
      dataField: "createdWhen",
      text: "Vytvořeno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "validTo",
      text: "Platí do",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "occupancy",
      text: "Obsazenost",
      sort: true,
    },
    {
      dataField: "confirmed",
      text: "Schváleno",
      sort: true,
      formatter: confirmedColumnFormatter,
    },
    {
      dataField: "attributes",
      text: "Atributy",
      hidden: !userContext.user?.isAdmin,
      formatter: attributesColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    queryOrder
      ? {
          dataField: queryOrder,
          order: "asc",
        }
      : {
          dataField: "createdWhen",
          order: "desc",
        },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/topics/${row.id}`);
    },
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={filteredTopics}
      columns={gridColumns}
      search={true}
      exportCSV={{
        fileName: "seznam-témat-utf8.csv",
        onlyExportFiltered: true,
        blobType: "text/plain;charset=utf-8",
        noAutoBOM: true,
      }}
    >
      {(toolkitprops) => (
        <Fragment>
          <h5 id="tabelLabel">Témata</h5>
          <hr />
          <div className="row">
            <div className="col-md-2">
              <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-4 pt-2 text-right">
                  <span>Typ tématu</span>
                </div>
                <div className="col-8">
                  <AvForm>
                    <AvField
                      type="select"
                      name="topicType"
                      onChange={(e) => changeTopicType(e.target.value)}
                      value={selectedTopicTypeId}
                      defaultValue={0}
                    >
                      <option key={0} value={0}>
                        Vše
                      </option>
                      {topicTypes.map((topicType) => {
                        return (
                          <option key={topicType.id} value={topicType.id}>
                            {topicType.name}
                          </option>
                        );
                      })}
                    </AvField>
                  </AvForm>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-4 pt-2 text-right">
                  <span>Firma</span>
                </div>
                <div className="col-8">
                  <AvForm>
                    <AvField
                      type="select"
                      name="company"
                      onChange={(e) => changeCompany(e.target.value)}
                      value={selectedCompanyId}
                      defaultValue={0}
                    >
                      <option value={0}>Vše</option>
                      {companies.map((company) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        );
                      })}
                    </AvField>
                  </AvForm>
                </div>
              </div>
            </div>

            <div className="col-md-2 custom-control custom-checkbox pt-2 text-right">
              {userContext.user.isAdmin ? (
                <Fragment>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showDeleted"
                    name="showDeleted"
                    checked={showDeleted}
                    onChange={(e) => setShowDeleted(e.target.checked)}
                  />
                  <label
                    className="custom-control-label font-weight-normal"
                    htmlFor="showDeleted"
                  >
                    Zobrazit smazaná
                  </label>
                </Fragment>
              ) : null}

              {userContext.user.isGuarantor ? (
                <Fragment>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showMyTopicsOnly"
                    name="showMyTopicsOnly"
                    checked={showMyTopicsOnly}
                    onChange={(e) => setShowMyTopicsOnly(e.target.checked)}
                  />
                  <label
                    className="custom-control-label font-weight-normal"
                    htmlFor="showMyTopicsOnly"
                  >
                    Pouze moje
                  </label>
                </Fragment>
              ) : null}
            </div>

            <div className="col-md-2 text-right">
              <ExportCSVButton
                {...toolkitprops.csvProps}
                className="mr-2 mb-2 btn btn-primary"
                title="Export do CSV"
              >
                <FontAwesomeIcon icon="file-csv" />
              </ExportCSVButton>
              <Link to="/topics/new" className="btn btn-primary mb-2">
                <FontAwesomeIcon icon="plus" /> Nové téma
              </Link>
            </div>
          </div>

          <div className="card card-outline">
            <div className="card-header">
              <BootstrapTable
                {...toolkitprops.baseProps}
                keyField="id"
                data={filteredTopics}
                columns={gridColumns}
                pagination={paginationFactory({ sizePerPage: 25 })}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                rowClasses={rowClasses}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>

          <TopicDeleteModal
            isOpen={showDeleteModal}
            id={currentRowId}
            onClose={onDeleteModalClose}
            onDeleted={onDeleted}
          />
        </Fragment>
      )}
    </ToolkitProvider>
  );
}
