import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { Companies } from "./components/companies/Companies";
import { CompanyTabs } from "./components/companies/CompanyTabs";
import { CompanyRegistrationForm } from "./components/companies/CompanyRegistrationForm";
import { Topics } from "./components/topics/Topics";
import { TopicTabs } from "./components/topics/TopicTabs";
import { StudentDashboard } from "./components/students/StudentDashboard";
import { StudentTopicsTabs } from "./components/students/StudentTopicsTabs";
import { StudentTopicForm } from "./components/students/StudentTopicForm";
import { Users } from "./components/users/Users";
import { UserForm } from "./components/users/UserForm";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import { ThemeProvider } from "./components/contexts/ThemeContext";
import { UserProvider } from "./components/contexts/UserContext";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./adminlte.min.css";
import "./custom.css";
import { StudentRequests } from "./components/students/StudentRequests";
import { StudentRequestForm } from "./components/students/StudentRequestForm";
import { AdminDashboard } from "./components/admin/AdminDashboard";
import { CompanyDashboard } from "./components/companies/CompanyDashboard";
import { MyCompanyTabs } from "./components/companies/MyCompanyTabs";
import { NotFoundError } from "./components/common/NotFoundError";
import { EmailTemplates } from "./components/admin/EmailTemplates";
import { EmailTemplateForm } from "./components/admin/EmailTemplateForm";
import { StudentRegistrationDetails } from "./components/student-registrations/StudentRegistrationDetails";
import { StudentRegistrations } from "./components/student-registrations/StudentRegistrations";
import {
  faHome,
  faUser,
  faUsers,
  faIndustry,
  faHandshake,
  faPlus,
  faEdit,
  faTimes,
  faTrash,
  faTrashAlt,
  faCheck,
  faHourglassHalf,
  faEye,
  faCheckCircle,
  faArrowDown,
  faArrowUp,
  faTachometerAlt,
  faEnvelope,
  faUserPlus,
  faSignInAlt,
  faLayerGroup,
  faUserGraduate,
  faStream,
  faTools,
  faSignOutAlt,
  faCog,
  faFileCsv,
  faPause,
  faDownload,
  faMarker,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

// font awesome icons for the whole app
library.add(
  faHome,
  faUser,
  faUsers,
  faIndustry,
  faHandshake,
  faPlus,
  faEdit,
  faTimes,
  faTrash,
  faTrashAlt,
  faCheck,
  faHourglassHalf,
  faEye,
  faCheckCircle,
  faArrowDown,
  faArrowUp,
  faTachometerAlt,
  faEnvelope,
  faUserPlus,
  faSignInAlt,
  faLayerGroup,
  faUserGraduate,
  faStream,
  faTools,
  faSignOutAlt,
  faCog,
  faFileCsv,
  faPause,
  faDownload,
  faMarker,
  faCopy
);

export default class App extends Component {
  static displayName = App.name;

  render() {
    const { signedInUser, theme } = this.props;

    return (
      <ThemeProvider value={theme}>
        <UserProvider value={signedInUser}>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/home"
                render={(props) => <Home {...props} alwaysShowHome={true} />}
              />
              <Route
                exact
                path="/register-company"
                component={CompanyRegistrationForm}
              />
              <AuthorizeRoute
                exact
                path="/admin-dashboard"
                component={AdminDashboard}
              />
              <AuthorizeRoute
                exact
                path="/guarantor-dashboard"
                component={AdminDashboard}
              />
              <AuthorizeRoute exact path="/users" component={Users} />
              <AuthorizeRoute exact path="/users/:id" component={UserForm} />
              <AuthorizeRoute exact path="/companies" component={Companies} />
              <AuthorizeRoute
                exact
                path="/companies/:id"
                component={CompanyTabs}
              />
              <AuthorizeRoute
                exact
                path="/email-templates"
                component={EmailTemplates}
              />
              <AuthorizeRoute
                exact
                path="/email-templates/:id"
                component={EmailTemplateForm}
              />
              <AuthorizeRoute exact path="/topics" component={Topics} />
              <AuthorizeRoute
                exact
                path="/topics/:id/:copyofid?"
                component={TopicTabs}
              />
              <AuthorizeRoute
                exact
                path="/student-registration-details/:userid/:topicid"
                component={StudentRegistrationDetails}
              />
              <AuthorizeRoute
                exact
                path="/student-dashboard"
                component={StudentDashboard}
              />
              <AuthorizeRoute
                exact
                path="/student-topics"
                component={StudentTopicsTabs}
              />
              <AuthorizeRoute
                exact
                path="/student-topics/:id"
                component={StudentTopicForm}
              />
              <AuthorizeRoute
                exact
                path="/student-registrations"
                component={StudentRegistrations}
              />
              <AuthorizeRoute
                exact
                path="/student-requests"
                component={StudentRequests}
              />
              <AuthorizeRoute
                exact
                path="/student-requests/:id"
                component={StudentRequestForm}
              />
              <AuthorizeRoute
                exact
                path="/company-dashboard"
                component={CompanyDashboard}
              />
              <AuthorizeRoute
                exact
                path="/my-company"
                component={MyCompanyTabs}
              />
              <AuthorizeRoute
                exact
                path="/company-employees/:id"
                component={UserForm}
              />
              <Route
                path={ApplicationPaths.ApiAuthorizationPrefix}
                component={ApiAuthorizationRoutes}
              />
              <Route component={NotFoundError} />
            </Switch>
          </Layout>
        </UserProvider>
      </ThemeProvider>
    );
  }
}
