import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

export function CompanyStatsBoxes(props) {
  const history = useHistory();

  const redirectTo = (path) => {
    history.push(path);
  };

  return (
    <Fragment>
      {props.statsData ? (
        <Fragment>
          <div className="row col-12">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="info-box" onClick={() => redirectTo("/topics")}>
                <span className="info-box-icon bg-blue">
                  {props.statsData?.increments.topicCount}
                  <i className="ion ion-ios-gear-outline"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Nová témata</span>
                  <span className="info-box-number">
                    {props.statsData?.totals.topicCount}
                    &nbsp;<small>aktivních témat celkem</small>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div
                className="info-box"
                onClick={() => redirectTo("/student-registrations")}
              >
                <span className="info-box-icon bg-green">
                  {props.statsData?.increments.topicStudentCount}
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Přihlášení studenti</span>
                  <span className="info-box-number">
                    {" "}
                    {props.statsData?.totals.topicStudentCount}
                    &nbsp;<small>přihlášených celkem</small>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="info-box" onClick={() => redirectTo("/topics")}>
                <span className="info-box-icon bg-yellow">
                  {props.statsData?.increments.finishedTopicCount}
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Ukončená témata</span>
                  <span className="info-box-number">
                    {" "}
                    {props.statsData?.totals.finishedTopicCount}
                    &nbsp;<small>ukončených témat celkem</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
