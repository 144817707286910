import React, { useContext, useState, Fragment } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { LoginMenu } from "../api-authorization/LoginMenu";
import { MenuItemsStudent } from "./MenuItemsStudent";
import { MenuItemsPublic } from "./MenuItemsPublic";
import { MenuItemsAdmin } from "./MenuItemsAdmin";
import { MenuItemsGuarantor } from "./MenuItemsGuarantor";
import { MenuItemsCompanyManager } from "./MenuItemsCompanyManager";
import { MenuItemsCompanyEmployee } from "./MenuItemsCompanyEmployee";
import UserContext from "../contexts/UserContext";
import ThemeContext from "../contexts/ThemeContext";
import { matchPath } from "react-router";

export function NavMenu() {
  const [collapsed, setCollapsed] = useState(true);
  let location = useLocation();
  const userContext = useContext(UserContext);
  const themeContext = useContext(ThemeContext);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const isAuthenticationBeingProcessed = () => {
    return !!matchPath(location.pathname, "/Authentication");
  };

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 default-header"
        dark
      >
        <Container>
          <NavbarBrand
            className={themeContext.isLoading ? "animated-text" : ""}
            tag={Link}
            to="/home"
          >
            <span>
              <img
                alt="logo"
                className="logo-image"
                src="/images/fim_logo_white.png"
              />
              Portál pro spolupráci s praxí
            </span>
          </NavbarBrand>

          {!isAuthenticationBeingProcessed() && userContext?.isPopulated ? (
            <Fragment>
              <NavbarToggler onClick={toggleNavbar} className="mr-2" />
              <Collapse
                className="d-sm-inline-flex flex-sm-row-reverse"
                isOpen={!collapsed}
                navbar
              >
                <ul className="navbar-nav flex-grow">
                  {!userContext.isAuthenticated ? (
                    <MenuItemsPublic />
                  ) : (
                    <Fragment>
                      {userContext.user.isAdmin ? <MenuItemsAdmin /> : null}
                      {userContext.user.isStudent ? <MenuItemsStudent /> : null}
                      {userContext.user.isGuarantor ? (
                        <MenuItemsGuarantor />
                      ) : null}
                      {userContext.user.isCompanyManager ? (
                        <MenuItemsCompanyManager />
                      ) : null}
                      {userContext.user.isCompanyEmployee ? (
                        <MenuItemsCompanyEmployee />
                      ) : null}
                    </Fragment>
                  )}
                  <LoginMenu />
                </ul>
              </Collapse>
            </Fragment>
          ) : null}
        </Container>
      </Navbar>
    </header>
  );
}
