import React, { useState, useEffect, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useFetch from "../api-hooks/useFetch";

export function CompanySuspendModal(props) {
  const fetchService = useFetch("api/company");
  const [isSuspendModalOpen, setSuspendModalOpen] = useState(false);

  useEffect(() => {
    setSuspendModalOpen(props.isOpen);
  }, [props.isOpen]);

  const toggleModal = () => setSuspendModalOpen(!isSuspendModalOpen);

  const confirmSuspension = async () => {
    toggleModal();
    const companyData = await fetchService.put(
      "suspend/" + props.id,
      {},
      "Platnost firmy byla pozastavena"
    );
    props.onSuspended(companyData);
  };

  const closeModal = () => {
    toggleModal();
    props.onClose();
  };

  return (
    <Fragment>
      <Modal
        isOpen={isSuspendModalOpen}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal}>Potvrzení</ModalHeader>
        <ModalBody>
          Opravdu chcete platnost záznamu této firmy? Firma poté nebude moci
          přidávat nová témata.
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-primary" onClick={confirmSuspension}>
            Ano
          </Button>{" "}
          <Button className="btn btn-secondary" onClick={closeModal}>
            Ne
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
