import React, { Fragment, useState, useEffect, useContext } from "react";
import { Button, Label } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { BackButton } from "../common/BackButton";
import { AuditInfo } from "../common/AuditInfo";
import { DeletedInfo } from "../common/DeletedInfo";
import useFetch from "../api-hooks/useFetch";
import { useHistory } from "react-router-dom";
import UserContext from "../contexts/UserContext";

export function UserForm(props) {
  const fetchService = useFetch("api/user");
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [defaultCompanyId, setDefaultCompanyId] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [showCompanyDropDown, setShowCompanyDropDown] = useState(false);

  useEffect(function effectFunction() {
    setIsNewUser(props.match.params.id === "new");
    setCompanyIdFromUrl();

    async function fetchUser() {
      if (props.match.params.id) {
        const data = await fetchService.get(
          props.match.params.id + "?companyId=" + setCompanyIdFromUrl()
        );
        setUserData(data.user);
        setRoles(data.roles);
        setCompanies(data.companies);
      }
    }
    fetchUser();
  }, []);

  useEffect(
    function effectCompaniesAreSet() {
      if (companies.length === 1) {
        setCompanyId(companies[0].id);
      }
    },
    [companies]
  );

  useEffect(
    function effectRoleIsSet() {
      setShowCompanyDropDown(
        roles.find((r) => r.id === roleId && r.isCompanyRole)
      );
    },
    [roleId, roles, companyId]
  );

  useEffect(
    function effectCompanyIdIsSet() {
      if (companies.length > 0) {
        setShowCompanyDropDown(true);
      }
    },
    [companyId, companies]
  );

  const setCompanyIdFromUrl = () => {
    if (props.location.search.indexOf("companyId=") > -1) {
      const defaultId = props.location.search.substr(
        props.location.search.indexOf("=") + 1
      );
      setCompanyId(defaultId);
      setDefaultCompanyId(defaultId);

      return defaultId;
    }

    return "";
  };

  const setUserData = (user) => {
    if (user) {
      setUser(user);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setRoleId(user.roleId);

      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setDeleted(user.deleted);

      if (user.companyId && !companyId) {
        setCompanyId(user.companyId ?? companyId);
      }
    }
  };

  const submitNew = async (e) => {
    e.preventDefault();
    const newUser = await fetchService.post({
      username: email,
      roleId: roleId,
      companyId: companyId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
    });

    if (newUser?.id) {
      setIsNewUser(false);
      setUserData(newUser);
      setTimeout(function () {
        history.push(
          userContext.user?.isCompanyUser
            ? "/my-company?tabId=2"
            : userContext.user?.isGuarantor
            ? `/companies/${companyId}?tabId=2`
            : "/users"
        );
      }, 3000);
    }
  };

  const submitEdit = async (e) => {
    e.preventDefault();
    const updatedUser = await fetchService.put(props.match.params.id, {
      id: props.match.params.id,
      username: email,
      firstName: firstName,
      lastName: lastName,
      companyId: companyId,
      roleId: roleId,
      email: email,
      phoneNumber: phoneNumber,
    });
    setUserData(updatedUser);
    if (updatedUser?.id) {
      setTimeout(function () {
        history.push("/users");
      }, 3000);
    }
  };

  const validateCompany = (value, ctx) => {
    if (ctx.roleId) {
      const role = roles.find((role) => role.id == ctx.roleId);
      if (role && role.isCompanyRole && ctx.companyId === "0") {
        return "Pro firemní role musí být firma vyplněna";
      }
    }

    return true;
  };

  return (
    <div>
      <Fragment>
        <div className="card card-outline form-container">
          <div className="card-header">
            <h5 id="tabelLabel">
              {isNewUser ? "Nový uživatel" : "Detail uživatele"}
            </h5>
            <hr />

            <DeletedInfo deleted={user?.deleted} />
            <AvForm
              onValidSubmit={isNewUser ? submitNew : submitEdit}
              className="mb-5"
            >
              <div className="row no-gutters mb-2">
                <AvGroup className="col-md-6 pr-2">
                  <Label htmlFor="firstName">Jméno:</Label>
                  <AvField
                    type="text"
                    name="firstName"
                    disabled={deleted}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Jméno je povinná položka",
                      },
                    }}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </AvGroup>
                <AvGroup className="col-md-6">
                  <Label htmlFor="lastName">Příjmení:</Label>
                  <AvField
                    type="text"
                    name="lastName"
                    disabled={deleted}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Příjmení je povinná položka",
                      },
                    }}
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </AvGroup>
              </div>

              <div className="row no-gutters mb-2">
                <AvGroup className="col-md-6 pr-2">
                  <Label htmlFor="email">Email (přihlašovací jméno):</Label>
                  <AvField
                    type="text"
                    name="email"
                    disabled={deleted}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Email je povinná položka",
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </AvGroup>

                <AvGroup className="col-md-6">
                  <Label htmlFor="phoneNumber">Telefon:</Label>
                  <AvField
                    type="text"
                    name="phoneNumber"
                    disabled={deleted}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                  />
                </AvGroup>
              </div>

              <div className="row no-gutters mb-2">
                <AvGroup className="col-md-6 pr-2">
                  <Label htmlFor="roleId">Role:</Label>
                  <AvField
                    type="select"
                    name="roleId"
                    disabled={deleted}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Role je povinná položka",
                      },
                    }}
                    onChange={(e) => setRoleId(e.target.value)}
                    value={user?.roleId ?? ""}
                  >
                    <option>Vyberte roli...</option>
                    {roles.map((role) => {
                      return (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      );
                    })}
                  </AvField>
                </AvGroup>

                {showCompanyDropDown ? (
                  <AvGroup className="col-md-6">
                    <Label htmlFor="roleId">Firma:</Label>
                    <AvField
                      type="select"
                      name="companyId"
                      disabled={deleted}
                      readOnly={defaultCompanyId}
                      validate={{ validateCompany }}
                      onChange={(e) => setCompanyId(e.target.value)}
                      value={companyId}
                    >
                      <option value="0">Vyberte firmu...</option>
                      {companies.map((company) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        );
                      })}
                    </AvField>
                  </AvGroup>
                ) : null}
              </div>

              <Button className="btn btn-primary mr-2">Uložit</Button>
              <BackButton />
            </AvForm>
            {!isNewUser ? <AuditInfo auditData={user} /> : null}
          </div>
        </div>
      </Fragment>
    </div>
  );
  // }
}

//UserForm.contextType = ThemeContext;
