import React, { useState, useEffect, useContext, Fragment } from "react";
import useFetch from "../api-hooks/useFetch";
import UserContext from "../contexts/UserContext";
import { useHistory, Route } from "react-router-dom";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
//import AvDate from "@availity/reactstrap-validation-date";
//import AvDate, { AvDateField, AvDateRange, AvDateRangeField } from '@availity/reactstrap-validation-date';
import { Button, Label, FormText, FormGroup } from "reactstrap";
import { BackButton } from "../common/BackButton";
import { AuditInfo } from "../common/AuditInfo";
import { DeletedInfo } from "../common/DeletedInfo";
import { DeliveryModal } from "./DeliveryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";

export function StudentRegistrationDetails(props) {
  const fetchService = useFetch("api/student-registration-details");
  const userContext = useContext(UserContext);
  const [isDeliveryModalOpen, setDeliveryModalOpen] = useState(false);
  const history = useHistory();
  const FileSaver = require("file-saver");

  const [registration, setRegistration] = useState({
    firstName: null,
    lastName: null,
  });

  useEffect(function effectFunction() {
    async function fetchRegistration() {
      const data = await fetchService.get(
        props.match.params.userid + "/" + props.match.params.topicid
      );
      if (data) {
        setRegistration(data);
      }
    }
    fetchRegistration();
  }, []);

  const confirmRegistrationByCompany = async () => {
    const body = {
      userId: props.match.params.userid,
    };
    const data = await fetchService.put(
      `confirm-by-company/${
        props.match.params.userid + "/" + props.match.params.topicid
      }`,
      body
    );
    setRegistration(data);
  };

  const confirmRegistrationByUniversity = async () => {
    const body = {
      userId: props.match.params.userid,
    };
    const data = await fetchService.put(
      `confirm-by-university/${
        props.match.params.userid + "/" + props.match.params.topicid
      }`,
      body
    );
    setRegistration(data);
  };

  const setCreditsGiven = async () => {
    await resolveCredits(true);
  };

  const setCreditsDenied = async () => {
    await resolveCredits(false);
  };

  const resolveCredits = async (creditsGiven) => {
    const body = {
      creditsGiven,
    };
    const data = await fetchService.put(
      `resolve-credits/${
        props.match.params.userid + "/" + props.match.params.topicid
      }`,
      body
    );
    setRegistration(data);
  };

  const openDeliveryModal = () => {
    setDeliveryModalOpen(true);
  };

  const onDeliveryModalClose = () => {
    setDeliveryModalOpen(false);
  };

  const resetRegistrationData = (registrationData) => {
    setRegistration(registrationData);
  };

  const downloadFinalReport = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const blob = await fetchService
      .download(
        `get-final-report/${
          props.match.params.userid + "/" + props.match.params.topicid
        }`
      )
      .then((blob) => {
        FileSaver.saveAs(blob, registration.finalReportOriginalFileName);
      });
  };

  return (
    <div className="card card-outline form-container">
      <div className="card-header">
        <h5 id="tabelLabel">Detaily registrace studenta na téma</h5>
        <hr />

        <DeletedInfo deleted={registration?.deleted} />

        {registration?.confirmedAtCompany === false && registration ? (
          <div className="alert alert-warning pr-2" role="alert">
            Přihláška studenta zatím nebyla schválena firmou.
            {!registration?.deleted &&
            userContext.user &&
            userContext.user.isCompanyUser ? (
              <Button
                className="btn btn-primary float-right"
                onClick={confirmRegistrationByCompany}
              >
                Schválit
              </Button>
            ) : null}
          </div>
        ) : null}

        {registration?.confirmedAtUniversity === false &&
        registration?.confirmedAtCompany &&
        registration ? (
          <div className="alert alert-warning pr-2" role="alert">
            Přihláška studenta zatím nebyla schválena univerzitou.
            {!registration?.deleted &&
            userContext.user &&
            (userContext.user.isAdmin || userContext.user.isGuarantor) ? (
              <Button
                className="btn btn-primary float-right"
                onClick={confirmRegistrationByUniversity}
              >
                Schválit
              </Button>
            ) : null}
          </div>
        ) : null}

        {registration &&
        registration.confirmedAtCompany &&
        registration.confirmedAtUniversity &&
        !registration.deliveredWhen &&
        userContext.user &&
        userContext.user.isStudent ? (
          <div className="alert alert-success pr-2" role="alert">
            Práce zatím nebyla předána. Chcete nahrát závěrečnou zprávu a
            ukončit práci na tématu?
            {!registration?.deleted &&
            userContext.user &&
            (userContext.user.isUniversityUser ||
              userContext.user.isStudent) ? (
              <Button
                className="btn btn-primary float-right"
                onClick={openDeliveryModal}
              >
                Předat
              </Button>
            ) : null}
          </div>
        ) : null}

        {registration &&
        registration.confirmedAtCompany &&
        registration.confirmedAtUniversity &&
        !registration.creditsResolvedWhen &&
        userContext.user &&
        (userContext.user.isGuarantor || userContext.user.isAdmin) ? (
          <div className="alert alert-warning pr-2" role="alert">
            Kredity zatím nebyly schváleny ani zamítnuty.
            <Button
              className="btn btn-primary float-right ml-2"
              onClick={setCreditsGiven}
            >
              Schválit
            </Button>
            <Button
              className="btn btn-danger float-right"
              onClick={setCreditsDenied}
            >
              Zamítnout
            </Button>
          </div>
        ) : null}

        <AvForm>
          <div className="row no-gutters">
            <AvGroup className="col-md-6 pr-2">
              <Label htmlFor="topicTitle">Název tématu</Label>
              <AvField
                type="text"
                name="topicTitle"
                disabled={true}
                value={registration?.topicTitle}
              />
            </AvGroup>
            <AvGroup className="col-md-6 pr-2">
              <Label htmlFor="topicType">Typ</Label>
              <AvField
                type="text"
                name="topicType"
                disabled={true}
                value={registration?.topicType}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-12 pr-2 mb-0">
              <Label htmlFor="topicDescription">Popis</Label>
              <AvField
                type="textarea"
                name="topicDescription"
                disabled={true}
                value={registration?.topicDescription}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters mb-2">
            <AvGroup className="col-md-6 pr-2">
              <Label htmlFor="firstName">Jméno:</Label>
              <AvField
                type="text"
                name="firstName"
                disabled={true}
                value={registration?.firstName}
              />
            </AvGroup>
            <AvGroup className="col-md-6">
              <Label htmlFor="lastName">Příjmení:</Label>
              <AvField
                type="text"
                name="lastName"
                disabled={true}
                value={registration?.lastName}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters mb-2">
            <AvGroup className="col-md-6 pr-2">
              <Label htmlFor="firstName">Email:</Label>
              <AvField
                type="text"
                name="firstName"
                disabled={true}
                value={registration?.email}
              />
            </AvGroup>
            <AvGroup className="col-md-6">
              <Label htmlFor="phoneNumber">Telefon:</Label>
              <AvField
                type="text"
                name="phoneNumber"
                disabled={true}
                value={
                  registration?.phoneNumber ? "" : registration?.phoneNumber
                }
              />
            </AvGroup>
          </div>

          <div className="row no-gutters mb-2">
            <AvGroup className="col-md-3 pr-2">
              <Label htmlFor="createdWhen">Datum přihlášení studenta</Label>
              <AvField
                type="date"
                name="createdWhen"
                disabled={true}
                value={registration?.createdWhen?.substr(
                  0,
                  registration.createdWhen.indexOf("T")
                )}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-md-3 pr-2">
              <Label htmlFor="confirmedAtCompanyWhen">
                Datum potvrzení firmou
              </Label>
              <AvField
                type="date"
                name="confirmedAtCompanyWhen"
                disabled={true}
                value={registration?.confirmedAtCompanyWhen?.substr(
                  0,
                  registration.confirmedAtCompanyWhen.indexOf("T")
                )}
              />
            </AvGroup>
            <div className="col-md-3"></div>
            <AvGroup className="col-md-6">
              <Label htmlFor="confirmedAtCompanyBy">Zástupce firmy</Label>
              <AvField
                type="text"
                name="confirmedAtCompanyBy"
                disabled={true}
                value={registration?.confirmedAtCompanyBy ?? ""}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters mb-2">
            <AvGroup className="col-md-3 pr-2">
              <Label htmlFor="confirmedAtUniversityWhen">
                Datum potvrzení univerzitou
              </Label>
              <AvField
                type="date"
                name="confirmedAtUniversityWhen"
                disabled={true}
                value={registration?.confirmedAtUniversityWhen?.substr(
                  0,
                  registration.confirmedAtUniversityWhen.indexOf("T")
                )}
              />
            </AvGroup>
            <div className="col-md-3"></div>
            <AvGroup className="col-md-6">
              <Label htmlFor="confirmedAtUniversityBy">
                Zástupce univerzity
              </Label>
              <AvField
                type="text"
                name="confirmedAtUniversityBy"
                disabled={true}
                value={registration?.confirmedAtUniversityBy ?? ""}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-md-3 pr-2">
              <Label htmlFor="deliveredWhen">
                Datum předání práce studentem
              </Label>
              <AvField
                type="date"
                name="deliveredWhen"
                disabled={true}
                value={registration?.deliveredWhen?.substr(
                  0,
                  registration.deliveredWhen.indexOf("T")
                )}
              />
            </AvGroup>
            <div className="col-md-3"></div>
            <AvGroup className="col-md-6">
              <Label htmlFor="confirmedAtCompanyBy">&nbsp;</Label>
              {registration?.finalReportFileName ? (
                <div className="row no-gutters mb-4">
                  <a
                    download
                    target="_blank"
                    href="#"
                    onClick={downloadFinalReport}
                  >
                    <FontAwesomeIcon icon="download" />
                    &nbsp;Závěrečná zpráva
                  </a>
                </div>
              ) : null}
            </AvGroup>
          </div>

          <div className="row no-gutters mb-2">
            <AvGroup className="col-md-3 pr-2">
              <Label htmlFor="creditsResolvedWhen">
                Datum přidělení/zamítnutí kreditů
              </Label>
              <AvField
                type="date"
                name="creditsResolvedWhen"
                disabled={true}
                value={registration?.creditsResolvedWhen?.substr(
                  0,
                  registration.creditsResolvedWhen.indexOf("T")
                )}
              />
            </AvGroup>
            <div className="col-md-3">
              <Label htmlFor="creditsResolvedWhen" className="d-block">
                &nbsp;
              </Label>

              {registration?.creditsResolvedWhen ? (
                registration.creditsGiven ? (
                  <div className="text-success pt-2">
                    <FontAwesomeIcon icon="marker" title="Kredity uděleny" />
                    &nbsp;Kredity uděleny
                  </div>
                ) : registration.creditsDenied ? (
                  <div className="text-danger pt-2">
                    <FontAwesomeIcon icon="marker" title="Kredity zamítnuty" />
                    &nbsp;Kredity zamítnuty
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <AvGroup className="col-md-6">
              <Label htmlFor="creditsResolvedBy">
                Zástupce univerzity potvrzující kredity
              </Label>
              <AvField
                type="text"
                name="creditsResolvedBy"
                disabled={true}
                value={registration?.creditsResolvedBy ?? ""}
              />
            </AvGroup>
          </div>
        </AvForm>

        <BackButton url="/student-registrations" />

        <AuditInfo auditData={registration} />

        <DeliveryModal
          isOpen={isDeliveryModalOpen}
          userId={registration?.userId}
          topicId={registration?.topicId}
          onClose={onDeliveryModalClose}
          onDelivered={resetRegistrationData}
        />
      </div>
    </div>
  );
}
