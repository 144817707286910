import React, { Fragment } from "react";

export function DeletedInfo(props) {
  return (
    <Fragment>
      {props.deleted === true ? (
        <div className="alert alert-danger" role="alert">
          Tento záznam byl označen jako smazaný a je viditelný pouze pro
          administrátory.
        </div>
      ) : null}
    </Fragment>
  );
}
