import React, { useState } from "react";
import { Button, Label } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import { BackButton } from "../common/BackButton";
import useFetch from "../api-hooks/useFetch";
import { useHistory } from "react-router-dom";

export function CompanyRegistrationForm(props) {
  const fetchService = useFetch("api/company");
  const history = useHistory();
  const [
    companyIdentificationNumber,
    setCompanyIdentificationNumber,
  ] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyStreetAddress, setCompanyStreetAddress] = useState("");
  const [companyTown, setCompanyTown] = useState("");
  const [companyPostcode, setCompanyPostcode] = useState("");
  const [personFirstName, setPersonFirstName] = useState("");
  const [personLastName, setPersonLastName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personPhoneNumber, setPersonPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [gdprAgreed, setGdprAgreed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    e.preventDefault();
    const body = {
      companyIdentificationNumber,
      companyName,
      companyStreetAddress,
      companyTown,
      companyPostcode,
      personFirstName,
      personLastName,
      personEmail,
      personPhoneNumber,
      password,
      passwordConfirmation,
      gdprAgreed,
    };

    const responseData = await fetchService.post(
      body,
      "register",
      "Firma byla úspěšně registrována. Na Vaši emailovou adresu byly odeslány informace o dalším postupu."
    );

    if (responseData === true) {
      setTimeout(function () {
        history.push("/");
      }, 3000);
    }
  };

  const findCompany = async () => {
    const json = await fetchService.get(
      `ares/${companyIdentificationNumber}`,
      "Firma úspěšně nalezena"
    );

    if (json?.name) {
      setCompanyName(json.name);
      setCompanyStreetAddress(json.streetAddress);
      setCompanyTown(json.town);
      setCompanyPostcode(json.postcode);
    }
  };

  return (
    <div>
      <h5 id="tabelLabel">Registrace firmy</h5>
      <hr />
      <AvForm onValidSubmit={handleSubmit}>
        <div className="row no-gutters">
          <AvGroup className="col-3 mb-1">
            <Label htmlFor="companyIdentificationNumber">IČO</Label>
            <AvField
              type="number"
              name="companyIdentificationNumber"
              validate={{
                required: {
                  value: true,
                  errorMessage: "IČO je povinná položka",
                },
                pattern: {
                  value: "^[0-9]+$",
                  errorMessage: "Povolené jsou číslice",
                },
                minLength: {
                  value: 8,
                  errorMessage: "Minimální délka je 8 znaků",
                },
                maxLength: {
                  value: 8,
                  errorMessage: "Maximální délka je 8 znaků",
                },
              }}
              onChange={(e) => setCompanyIdentificationNumber(e.target.value)}
              value={companyIdentificationNumber}
            />
          </AvGroup>
          <AvGroup className="col-3 mb-1">
            <Label htmlFor="aresButton" className="col-12">
              &nbsp;
            </Label>
            <Button
              className="btn btn-primary ml-3"
              type="button"
              name="aresButton"
              disabled={
                !companyIdentificationNumber ||
                companyIdentificationNumber.length !== 8
              }
              onClick={findCompany}
            >
              Vyhledat v ARESu
            </Button>
          </AvGroup>
        </div>
        <div className="row no-gutters">
          <AvGroup className="col-6 pr-2 mb-1">
            <Label htmlFor="companyName">Název společnosti</Label>
            <AvField
              type="text"
              name="companyName"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Název společnosti je povinná položka",
                },
              }}
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            />
          </AvGroup>
          <AvGroup className="col-6 pl-2 mb-1">
            <Label htmlFor="companyStreetAddress">Ulice a č.p.</Label>
            <AvField
              type="text"
              name="companyStreetAddress"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Ulice a č.p. je povinná položka",
                },
              }}
              onChange={(e) => setCompanyStreetAddress(e.target.value)}
              value={companyStreetAddress}
            />
          </AvGroup>
        </div>

        <div className="row no-gutters">
          <AvGroup className="col-6 pr-2 mb-1">
            <Label htmlFor="companyTown">Obec</Label>
            <AvField
              type="text"
              name="companyTown"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Obec je povinná položka",
                },
              }}
              onChange={(e) => setCompanyTown(e.target.value)}
              value={companyTown}
            />
          </AvGroup>
          <AvGroup className="col-3 pl-2 mb-1">
            <Label htmlFor="companyPostcode">PSČ</Label>
            <AvField
              type="text"
              name="companyPostcode"
              validate={{
                required: {
                  value: true,
                  errorMessage: "PSČ je povinná položka",
                },
              }}
              onChange={(e) => setCompanyPostcode(e.target.value)}
              value={companyPostcode}
            />
          </AvGroup>
        </div>

        <div className="row no-gutters">
          <AvGroup className="col-6 pr-2 mb-1">
            <Label htmlFor="personFirstName">Jméno</Label>
            <AvField
              type="text"
              name="personFirstName"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Jméno je povinná položka",
                },
              }}
              onChange={(e) => setPersonFirstName(e.target.value)}
              value={personFirstName}
            />
          </AvGroup>
          <AvGroup className="col-6 pl-2 mb-1">
            <Label htmlFor="personLastName">Příjmení</Label>
            <AvField
              type="text"
              name="personLastName"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Příjmení je povinná položka",
                },
              }}
              onChange={(e) => setPersonLastName(e.target.value)}
              value={personLastName}
              errorMessage="eee"
            />
          </AvGroup>
        </div>

        <div className="row no-gutters">
          <AvGroup className="col-6 pr-2 mb-1">
            <AvField
              name="personEmail"
              label="Email"
              type="email"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Email je povinná položka",
                },
                email: {
                  value: true,
                  errorMessage: "Email nemá správný formát",
                },
              }}
              onChange={(e) => setPersonEmail(e.target.value)}
              value={personEmail}
            />
          </AvGroup>
          <AvGroup className="col-3 pl-2 mb-1">
            <AvField
              name="personPhoneNumber"
              label="Telefon"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Telefon je povinná položka",
                },
              }}
              onChange={(e) => setPersonPhoneNumber(e.target.value)}
              value={personPhoneNumber}
            />
          </AvGroup>
        </div>

        <div className="row no-gutters">
          <AvGroup className="col-6 pr-2 mb-1">
            <AvField
              name="password"
              label="Heslo"
              type="password"
              autocomplete="new-password"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Heslo je povinná položka",
                },
                minLength: {
                  value: 8,
                  errorMessage: "Minimální délka je 8 znaků",
                },
              }}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </AvGroup>
          <AvGroup className="col-6 pl-2 mb-1">
            <AvField
              name="passwordConfirmation"
              label="Potvrzení hesla"
              type="password"
              validate={{
                match: {
                  value: "password",
                  errorMessage: "Potvrzení hesla nesouhlasí",
                },
                required: {
                  value: true,
                  errorMessage: "Potvrzení hesla je povinná položka",
                },
              }}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              value={passwordConfirmation}
            />
          </AvGroup>
        </div>
        <div className="row no-gutters mb-5">
          <AvGroup check className="pl-0">
            <div className="custom-control custom-checkbox">
              <AvInput
                type="checkbox"
                className="custom-control-input"
                name="gdprAgreed"
                id="gdprAgreed"
                checked={gdprAgreed}
                validate={{
                  required: {
                    value: true,
                    errorMessage:
                      "Souhlas se zpracováním osobních údajů je nutné potvrdit",
                  },
                }}
                onChange={(e) => {
                  setGdprAgreed(e.target.checked);
                }}
              />
              <Label check className="custom-control-label" for="gdprAgreed">
                Souhlas se zpracováním osobních údajů
              </Label>
            </div>
          </AvGroup>
        </div>

        <Button className="btn btn-primary mr-2">Registrovat</Button>
        <BackButton />
      </AvForm>
    </div>
  );
}
