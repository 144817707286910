import React, { Fragment } from "react";
import { NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export function MenuItemsCompanyManager() {
  return (
    <Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/company-dashboard">
          <FontAwesomeIcon icon="tachometer-alt" /> Úvod
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/topics">
          <FontAwesomeIcon icon="stream" /> Témata
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-registrations">
          <FontAwesomeIcon icon="sign-in-alt" /> Přihlášky
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-requests">
          <FontAwesomeIcon icon="user-graduate" /> Poptávky
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/my-company">
          <FontAwesomeIcon icon="industry" /> Firma
        </NavLink>
      </NavItem>
    </Fragment>
  );
}
