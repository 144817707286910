import React, { Fragment, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserContext from "./contexts/UserContext";
import { AdminDashboard } from "./admin/AdminDashboard";
import { CompanyDashboard } from "./companies/CompanyDashboard";
import { StudentDashboard } from "./students/StudentDashboard";

export function Home(props) {
  const userContext = useContext(UserContext);

  return (
    <Fragment>
      {userContext?.isPopulated ? (
        <Fragment>
          {!userContext.isAuthenticated || props.alwaysShowHome ? (
            <div className="align-center">
              <img
                src={"./images/fim-uhk-cs_hor.svg"}
                alt="UHK logo"
                className="half-width"
              />
              <hr />
              <div className="full-width-title">
                <FontAwesomeIcon icon="handshake" />
              </div>
              <div className="full-width-title">
                Portál pro spolupráci s praxí
              </div>
              <hr />
              <img src={"./images/workflow.svg"} alt="Workflow aplikace" />
            </div>
          ) : userContext.user.isAdmin || userContext.user.isGuarantor ? (
            <Fragment>
              <AdminDashboard />
            </Fragment>
          ) : userContext.user.isStudent ? (
            <Fragment>
              <StudentDashboard />
            </Fragment>
          ) : userContext.user.isCompanyManager ||
            userContext.user.isCompanyEmployee ? (
            <Fragment>
              <CompanyDashboard />
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );
}
