import React, { useState, useEffect, useContext, Fragment } from "react";
import useFetch from "../api-hooks/useFetch";
import UserContext from "../contexts/UserContext";
import { useHistory } from "react-router-dom";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Button, Label } from "reactstrap";
import { BackButton } from "../common/BackButton";
import { AuditInfo } from "../common/AuditInfo";
import { DeletedInfo } from "../common/DeletedInfo";
import { TopicDeleteModal } from "./TopicDeleteModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function TopicForm(props) {
  const fetchService = useFetch("api/topic");
  const userContext = useContext(UserContext);
  const history = useHistory();

  const [topic, setTopic] = useState("");
  const [isNewTopic, setIsNewTopic] = useState(true);
  const [title, setTitle] = useState("");
  const [topicTypeId, setTopicTypeId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [myCompanyId, setMyCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyContactUserId, setCompanyContactUserId] = useState("");
  const [description, setDescription] = useState("");
  const [capacity, setCapacity] = useState(1);
  const [validTo, setValidTo] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmedWhen, setConfirmedWhen] = useState(null);
  const [deliveredWhen, setDeliveredWhen] = useState(null);
  const [closingWhen, setClosingWhen] = useState(null);
  const [registeredStudentCount, setRegisteredStudentCount] = useState(0);
  const [deleted, setDeleted] = useState(false);
  const [topicTypes, setTopicTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyEmployees, setCompanyEmployees] = useState([]);
  const [filteredCompanyEmployees, setFilteredCompanyEmployees] = useState([]);
  const [guarantors, setGuarantors] = useState([]);
  const [guarantorUserId, setGuarantorUserId] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDisabledTopic, setIsDisabledTopic] = useState(false);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState("");
  const [quillClassName, setQuillClassName] = useState("");

  useEffect(function effectFunction() {
    const isNew = props.topicId === "new";
    setIsNewTopic(isNew);

    async function fetchTopic() {
      const data = await fetchService.get(
        isNew ? `new/${props.copyOfId ? props.copyOfId : ""}` : props.topicId
      );
      if (data) {
        if (Array.isArray(data.topicTypes)) {
          setTopicTypes(data.topicTypes);
        }
        if (Array.isArray(data.companies)) {
          setCompanies(data.companies);
        }
        if (Array.isArray(data.companyEmployees)) {
          setCompanyEmployees(data.companyEmployees);
        }
        if (Array.isArray(data.guarantors)) {
          setGuarantors(data.guarantors);
        }
        if (data.topic) {
          setTopicData(data.topic);
        }
        if (data.myCompanyId) {
          setMyCompanyId(data.myCompanyId);
          setCompanyId(data.myCompanyId);
        }
        props.setStudentsTabVisibility(data.showTopicStudents);
      }
    }
    fetchTopic();
  }, []);

  useEffect(
    function effectFunction2() {
      setIsDisabledTopic(
        !isNewTopic &&
          userContext.user.isCompanyUser &&
          myCompanyId !== companyId
      );

      setFilteredCompanyEmployees(
        companyEmployees.filter((e) => e.companyId == companyId)
      );
    },
    [isNewTopic, myCompanyId, companyId]
  );

  const setTopicData = (topic) => {
    setTopic(topic);
    setTitle(topic.title);
    setTopicTypeId(topic.topicTypeId);
    setCompanyId(topic.companyId);
    setCompanyName(topic.companyName);
    setDescription(topic.description);
    setCapacity(topic.capacity);
    setRegisteredStudentCount(topic.registeredStudentCount);
    setValidTo(topic.validTo.substr(0, topic.validTo.indexOf("T")));
    if (topic.confirmedWhen) {
      setConfirmedWhen(
        topic.confirmedWhen.substr(0, topic.confirmedWhen.indexOf("T"))
      );
    }
    if (topic.closingWhen) {
      setClosingWhen(
        topic.closingWhen.substr(0, topic.closingWhen.indexOf("T"))
      );
    }
    if (topic.deliveredWhen) {
      setDeliveredWhen(
        topic.deliveredWhen.substr(0, topic.deliveredWhen.indexOf("T"))
      );
    }
    setDeleted(topic.deleted);
    setConfirmed(topic.confirmed);
    setCompanyContactUserId(topic.companyContactUserId);
    setGuarantorUserId(topic.guarantorUserId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      id: parseInt(props.topicId),
      title,
      topicTypeId,
      companyId,
      companyContactUserId,
      guarantorUserId,
      description,
      capacity,
      validTo,
      confirmedWhen,
      deliveredWhen,
      closingWhen,
    };

    var saveResponse;

    if (isNewTopic) {
      saveResponse = await fetchService.post(body);
    } else {
      saveResponse = await fetchService.put(props.topicId, body);
    }

    if (saveResponse?.id) {
      setTimeout(function () {
        history.push("/topics");
      }, 3000);
    }
  };

  const confirmTopic = async () => {
    const data = await fetchService.put(`confirm/${parseInt(props.topicId)}`, {
      id: props.topicId,
    });
    setTopicData(data);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const backToList = () => {
    history.push(`/companies`);
  };

  const updateDescription = (value) => {
    var isEmpty = value.replace(/<(.|\n)*?>/g, "").trim().length === 0;
    setIsDescriptionEmpty(isEmpty);
    setQuillClassName(isEmpty ? "invalid" : "");
    setDescription(value);
  };

  return (
    <Fragment>
      <div className="card card-outline form-container">
        <div className="card-header">
          <DeletedInfo deleted={deleted} />
          {confirmed === false && topic && !isNewTopic ? (
            <div className="alert alert-warning pr-2" role="alert">
              Téma zatím nebylo schváleno univerzitou.
              {!deleted &&
              userContext.user &&
              (userContext.user.isAdmin || userContext.user.isGuarantor) ? (
                <Button
                  className="btn btn-primary float-right"
                  onClick={confirmTopic}
                >
                  Schválit
                </Button>
              ) : null}
            </div>
          ) : null}
          <AvForm onValidSubmit={handleSubmit}>
            <div className="row no-gutters">
              <AvGroup className="col-md-6 pr-2">
                <Label htmlFor="title">Název</Label>
                <AvField
                  type="text"
                  name="title"
                  disabled={deleted || isDisabledTopic}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Název tématu je povinná položka",
                    },
                  }}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </AvGroup>

              <AvGroup className="col-md-6 mb-1">
                <Label htmlFor="topicTypeId">Typ nabídky:</Label>
                <AvField
                  type="select"
                  name="topicTypeId"
                  disabled={deleted || isDisabledTopic}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Typ nabídky je povinná položka",
                    },
                  }}
                  onChange={(e) => setTopicTypeId(e.target.value)}
                  value={topicTypeId === null ? "" : topicTypeId}
                >
                  <option>Vyberte typ nabídky...</option>
                  {topicTypes.map((topicType) => {
                    return (
                      <option key={topicType.id} value={topicType.id}>
                        {topicType.name}
                      </option>
                    );
                  })}
                </AvField>
              </AvGroup>
            </div>

            <div className="row no-gutters">
              <AvGroup className="col-md-6 pr-2 mb-1">
                <Label htmlFor="companyId">Firma:</Label>
                {isDisabledTopic ? (
                  <AvField
                    type="text"
                    name="companyName"
                    disabled={true}
                    value={companyName}
                  />
                ) : (
                  <AvField
                    type="select"
                    name="companyId"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Firma je povinná položka",
                      },
                    }}
                    onChange={(e) => setCompanyId(e.target.value)}
                    value={
                      isNewTopic && myCompanyId
                        ? myCompanyId
                        : companyId === null
                        ? ""
                        : companyId
                    }
                    disabled={
                      deleted ||
                      isDisabledTopic ||
                      userContext.user.isCompanyManager ||
                      userContext.user.isCompanyEmployee
                    }
                  >
                    <option>Vyberte firmu...</option>
                    {companies.map((company) => {
                      return (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      );
                    })}
                  </AvField>
                )}
              </AvGroup>

              {!isDisabledTopic ? (
                <AvGroup className="col-md-6">
                  <Label htmlFor="CompanyContactUserId">Zástupce firmy:</Label>
                  <AvField
                    type="select"
                    name="CompanyContactUserId"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Zástupce firmy je povinná položka",
                      },
                    }}
                    onChange={(e) => setCompanyContactUserId(e.target.value)}
                    value={
                      companyContactUserId === null ? "" : companyContactUserId
                    }
                    disabled={deleted || isDisabledTopic}
                  >
                    <option>Vyberte zástupce firmy...</option>
                    {filteredCompanyEmployees.map((companyEmployee) => {
                      return (
                        <option
                          key={companyEmployee.id}
                          value={companyEmployee.id}
                        >
                          {companyEmployee.fullName}
                        </option>
                      );
                    })}
                  </AvField>
                </AvGroup>
              ) : null}
            </div>

            <div className="row no-gutters">
              <AvGroup className="col-12">
                <Label htmlFor="description">Popis</Label>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={updateDescription}
                  className={quillClassName}
                  readOnly={isDisabledTopic}
                />
                {isDescriptionEmpty ? (
                  <div class="invalid-feedback d-block">
                    Popis je povinná položka
                  </div>
                ) : null}
              </AvGroup>
            </div>

            <div className="row no-gutters">
              <AvGroup className="col-3 pr-2">
                <Label htmlFor="capacity">Počet míst</Label>
                <AvField
                  type="number"
                  name="capacity"
                  disabled={deleted || isDisabledTopic}
                  //min="1"
                  step="1"
                  validate={{
                    min: {
                      value: 1,
                      errorMessage: "Počet míst je povinná položka",
                    },
                    required: {
                      value: true,
                      errorMessage: "Počet míst je povinná položka",
                    },
                  }}
                  onChange={(e) => setCapacity(e.target.value)}
                  value={capacity}
                  placeholder={0}
                />
              </AvGroup>

              <AvGroup className="col-3 pr-2">
                <Label htmlFor="validTo">Platí do</Label>
                <AvField
                  type="date"
                  name="validTo"
                  disabled={deleted || isDisabledTopic}
                  validate={{
                    date: { format: "DD.MM.YYYY" },
                    required: {
                      value: true,
                      errorMessage: "Platí do je povinná položka",
                    },
                  }}
                  onChange={(e) => setValidTo(e.target.value)}
                  value={validTo}
                  placeholder={""}
                />
              </AvGroup>

              {!isNewTopic ? (
                <Fragment>
                  <AvGroup className="col-md-6">
                    <Label htmlFor="GuarantorUserId">
                      Garant tématu za UHK:
                    </Label>
                    <AvField
                      type="select"
                      name="GuarantorUserId"
                      onChange={(e) => setGuarantorUserId(e.target.value)}
                      value={guarantorUserId === null ? "" : guarantorUserId}
                      disabled={
                        deleted ||
                        !(
                          userContext.user &&
                          (userContext.user.isAdmin ||
                            userContext.user.isGuarantor)
                        )
                      }
                    >
                      {!userContext.user && userContext.user.isAdmin ? (
                        <option>Vyberte garanta tématu...</option>
                      ) : (
                        <option>Garant nezadán...</option>
                      )}

                      {guarantors.map((guarantor) => {
                        return (
                          <option key={guarantor.id} value={guarantor.id}>
                            {guarantor.fullName}
                          </option>
                        );
                      })}
                    </AvField>
                  </AvGroup>
                </Fragment>
              ) : null}
            </div>

            <div className="row no-gutters">
              {!isNewTopic ? (
                <Fragment>
                  <AvGroup className="col-3 pr-2">
                    <Label htmlFor="registeredStudentCount">
                      Počet registrovaných studentů
                    </Label>
                    <AvField
                      type="number"
                      name="registeredStudentCount"
                      disabled={true}
                      value={registeredStudentCount}
                      placeholder={0}
                    />
                  </AvGroup>

                  <AvGroup className="col-3 pr-2">
                    <Label htmlFor="confirmedWhen">Schváleno kdy</Label>
                    <AvField
                      type="date"
                      name="confirmedWhen"
                      disabled={true}
                      validate={{
                        date: { format: "DD.MM.YYYY" },
                      }}
                      value={confirmedWhen}
                    />
                  </AvGroup>
                  <AvGroup className="col-3 pr-2">
                    <Label htmlFor="closingWhen">Datum uzavření tématu</Label>
                    <AvField
                      type="date"
                      name="closingWhen"
                      disabled={deleted || isDisabledTopic}
                      validate={{
                        date: { format: "DD.MM.YYYY" },
                      }}
                      onChange={(e) => setClosingWhen(e.target.value)}
                      value={closingWhen}
                      placeholder={""}
                    />
                  </AvGroup>
                  <AvGroup className="col-3 pr-2">
                    <Label htmlFor="deliveredWhen">Datum převzetí</Label>
                    <AvField
                      type="date"
                      name="deliveredWhen"
                      disabled={deleted || isDisabledTopic}
                      validate={{
                        date: { format: "DD.MM.YYYY" },
                      }}
                      onChange={(e) => setDeliveredWhen(e.target.value)}
                      value={deliveredWhen}
                    />
                  </AvGroup>
                </Fragment>
              ) : null}
            </div>

            {!isNewTopic ? (
              <div className="row no-gutters mb-3">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="confirmed"
                    name="confirmed"
                    checked={confirmed}
                    disabled={true}
                  />
                  <label className="custom-control-label" htmlFor="confirmed">
                    Schválené téma
                  </label>
                </div>
              </div>
            ) : null}

            {!deleted &&
            userContext.user &&
            (userContext.user.isAdmin ||
              userContext.user.isGuarantor ||
              (userContext.user.isCompanyUser &&
                (myCompanyId === topic.companyId ||
                  (myCompanyId && !topic)))) ? (
              <Button className="btn btn-primary mr-2">Uložit</Button>
            ) : null}

            {!deleted && userContext.user && userContext.user.isAdmin ? (
              <Button className="btn btn-danger mr-2" onClick={openDeleteModal}>
                Smazat
              </Button>
            ) : null}
            <BackButton />
          </AvForm>
          <AuditInfo auditData={topic} />
          <TopicDeleteModal
            isOpen={isDeleteModalOpen}
            id={props.topicId}
            onClose={onDeleteModalClose}
            onDeleted={backToList}
          />
        </div>
      </div>
    </Fragment>
  );
}
