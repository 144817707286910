import React, { Fragment } from "react";

export function AppFooter() {
  return (
    <footer className="footer">
      <div className="container">
        <span>© {new Date().getFullYear()} UHK</span>
      </div>
    </footer>
  );
}
