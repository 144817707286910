import React, { Fragment } from "react";
import { NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export function MenuItemsStudent() {
  return (
    <Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-dashboard">
          <FontAwesomeIcon icon="home" /> Úvod
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-topics">
          <FontAwesomeIcon icon="stream" /> Nabídky
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-registrations">
          <FontAwesomeIcon icon="sign-in-alt" /> Přihlášky
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-requests">
          <FontAwesomeIcon icon="user-graduate" /> Poptávky
        </NavLink>
      </NavItem>
    </Fragment>
  );
}
