import React, { useState, useEffect, Fragment } from "react";
import useFetch from "../api-hooks/useFetch";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { BackButton } from "../common/BackButton";

export function StudentTopicForm(props) {
  const fetchService = useFetch("api/student-topic");
  const [topic, setTopic] = useState("");
  const [query, setQuery] = useState("");

  useEffect(function effectFunction() {
    async function fetchTopic() {
      const topic = await fetchService.get(props.match.params.id);
      if (topic) {
        setTopic(topic);
      }
    }
    fetchTopic();
  }, []);

  const [isSignInModalOpen, setSignInModal] = useState(false);
  const [isQueryModalOpen, setQueryModal] = useState(false);

  const toggleSignInModal = () => setSignInModal(!isSignInModalOpen);
  const toggleQueryModal = () => setQueryModal(!isQueryModalOpen);

  const confirmSignIn = async () => {
    const topic = await fetchService.post(parseInt(props.match.params.id));
    setTopic(topic);
    toggleSignInModal();
  };

  const sendQuery = async () => {
    const result = await fetchService.post(
      { id: parseInt(props.match.params.id), query },
      "query",
      "Email s dotazem byl úspěšně odeslán"
    );

    toggleQueryModal();
  };

  return (
    <div className="card card-outline form-container">
      <div className="card-header">
        <h5 id="tabelLabel">Detail tématu</h5>
        <hr />
        {topic?.currentUserSignedInWhen ? (
          <div className="alert alert-success" role="alert">
            Na toto téma jste byl(a) přihlášen(a){" "}
            {new Date(topic.currentUserSignedInWhen).toLocaleDateString()}.
          </div>
        ) : topic && !topic.isValid ? (
          <div className="alert alert-warning" role="alert">
            Na toto téma se již nelze přihlásit. Platnost tématu skončila
            {new Date(topic.validTo).toLocaleDateString()}.
          </div>
        ) : topic && !topic?.freeSlots > 0 ? (
          <div className="alert alert-warning" role="alert">
            Na toto téma se již nelze přihlásit. Byla již vyčerpána všechna
            volná místa.
          </div>
        ) : null}

        <AvForm>
          <div className="row no-gutters">
            <AvGroup className="col-12 pr-2 mb-0">
              <Label htmlFor="title">Název</Label>
              <AvField type="text" name="title" value={topic?.title} readOnly />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-0">
              <Label htmlFor="topicTypeId">Typ nabídky:</Label>
              <AvField
                type="text"
                name="topicTypeName"
                value={topic?.topicType?.name}
                readOnly
              ></AvField>
            </AvGroup>
            <AvGroup className="col-6 pr-2 mb-0">
              <Label htmlFor="companyId">Firma:</Label>
              <AvField
                type="text"
                name="companyName"
                value={topic?.companyName}
                readOnly
              ></AvField>
            </AvGroup>
          </div>

          <div className="row no-gutters pr-2">
            <div className="form-group col-12">
              <div className="col-12">
                <Label>Popis:</Label>
              </div>
              <div
                className="raw-html-block position-relative form-control col-12 rounded border"
                dangerouslySetInnerHTML={{ __html: topic?.description }}
              ></div>
            </div>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-0">
              <Label htmlFor="topicTypeId">Garant tématu za UHK:</Label>
              <AvField
                type="text"
                name="topicTypeName"
                value={topic?.guarantorName}
                readOnly
              ></AvField>
            </AvGroup>
            <AvGroup className="col-3 pr-2 mb-0">
              <Label htmlFor="capacity">Počet míst</Label>
              <AvField
                type="number"
                name="capacity"
                value={topic?.capacity}
                readOnly
              />
            </AvGroup>
            <AvGroup className="col-3 pr-2 mb-0">
              <Label htmlFor="capacity">Počet registrovaných studentů</Label>
              <AvField
                type="number"
                name="registeredStudentCount"
                disabled="disabled"
                value={topic?.registeredStudentCount}
                readOnly
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-3 pr-2 mb-0">
              <Label htmlFor="validTo">Platí do</Label>
              <AvField
                type="date"
                name="validTo"
                value={topic?.validTo?.substr(0, topic.validTo.indexOf("T"))}
                readOnly
              />
            </AvGroup>
          </div>

          <div className="mt-2 mb-2">
            <Button
              className="btn btn-primary mr-2"
              disabled={!topic?.isAvailableForSigningIn}
              onClick={toggleSignInModal}
            >
              Přihlásit se
            </Button>

            <Button className="btn btn-primary mr-2" onClick={toggleQueryModal}>
              Zaslat dotaz
            </Button>

            <BackButton />

            <Modal
              isOpen={isSignInModalOpen}
              toggle={toggleSignInModal}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={toggleSignInModal}>Potvrzení</ModalHeader>
              <ModalBody>Opravdu se chcete přihlásit na dané téma?</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={confirmSignIn}>
                  Ano
                </Button>{" "}
                <Button color="secondary" onClick={toggleSignInModal}>
                  Ne
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={isQueryModalOpen}
              toggle={toggleQueryModal}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={toggleQueryModal}>
                Zaslání dotazu
              </ModalHeader>
              <ModalBody>
                <AvGroup className="col-12 pr-2 mb-0">
                  Dotaz bude odeslán emailem na adresu TODO
                  <AvField
                    type="textarea"
                    name="query"
                    rows={8}
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                  />
                </AvGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={sendQuery}>
                  Odeslat
                </Button>{" "}
                <Button color="secondary" onClick={toggleQueryModal}>
                  Zpět
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </AvForm>
      </div>
    </div>
  );
}
