import React, { Fragment, useState, useEffect } from "react";
import { CompanyStatsTabs } from "./CompanyStatsTabs";
import useFetch from "../api-hooks/useFetch";
import { Link } from "react-router-dom";

export function CompanyDashboard() {
  const fetchService = useFetch("api/company-dashboard");
  const [companiesToConfirm, setCompaniesToConfirm] = useState(0);
  const [topicsToConfirm, setTopicsToConfirm] = useState(0);
  const [topicStudentsToConfirm, setTopicStudentsToConfirm] = useState(0);

  useEffect(() => {
    async function fetchItemsToConfirm() {
      const itemsToConfirms = await fetchService.get();
      if (!itemsToConfirms) {
        return;
      }

      setCompaniesToConfirm(itemsToConfirms.companiesToConfirm);
      setTopicsToConfirm(itemsToConfirms.topicsToConfirm);
      setTopicStudentsToConfirm(itemsToConfirms.topicStudentsToConfirm);
    }
    fetchItemsToConfirm();
  }, []);

  return (
    <Fragment>
      <h5 id="tabelLabel">Ke schválení</h5>
      <hr />
      {topicStudentsToConfirm || topicStudentsToConfirm === 0 ? (
        <Fragment>
          <div className="row mb-5">
            <Link to="/student-registrations">
              <button
                type="button"
                className={
                  "btn mr-2 " +
                  (topicStudentsToConfirm === 0 ? "btn-success" : "btn-warning")
                }
              >
                Přihlášky ke schválení{" "}
                <span className="badge badge-light">
                  {topicStudentsToConfirm}
                </span>
              </button>
            </Link>
          </div>
        </Fragment>
      ) : null}
      <h5 id="tabelLabel">Statistiky firmy</h5>
      <hr />
      <CompanyStatsTabs />
    </Fragment>
  );
}
