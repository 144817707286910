import React, { Fragment, useState, useEffect } from "react";
import { AdminStatsTabs } from "./AdminStatsTabs";
import useFetch from "../api-hooks/useFetch";
import { Link } from "react-router-dom";
import { COMPANY_STATUSES } from "../common/Constants";

export function AdminDashboard() {
  const fetchService = useFetch("api/admin-dashboard");
  const [companiesToConfirm, setCompaniesToConfirm] = useState(0);
  const [topicsToConfirm, setTopicsToConfirm] = useState(0);
  const [
    studentToTopicRegistrationsToConfirm,
    setStudentToTopicRegistrationsToConfirm,
  ] = useState(0);
  const [
    finishedTopicsWithoutStudentCredits,
    setFinishedTopicsWithoutStudentCredits,
  ] = useState(0);

  useEffect(() => {
    async function fetchItemsToConfirm() {
      const itemsToConfirms = await fetchService.get();
      if (!itemsToConfirms) {
        return;
      }

      setCompaniesToConfirm(itemsToConfirms.companiesToConfirm);
      setTopicsToConfirm(itemsToConfirms.topicsToConfirm);
      setStudentToTopicRegistrationsToConfirm(
        itemsToConfirms.studentToTopicRegistrationsToConfirm
      );
      setFinishedTopicsWithoutStudentCredits(
        itemsToConfirms.finishedTopicsWithoutStudentCredits
      );
    }
    fetchItemsToConfirm();
  }, []);

  return (
    <Fragment>
      <h5 id="tabelLabel">Ke schválení</h5>
      <hr />
      {companiesToConfirm || companiesToConfirm === 0 ? (
        <Fragment>
          <div className="row mb-4">
            <Link to={"/companies?status=" + COMPANY_STATUSES.UNCONFIRMED.id}>
              <button
                type="button"
                className={
                  "btn mr-2 " +
                  (companiesToConfirm === 0 ? "btn-success" : "btn-warning")
                }
              >
                Firmy ke schválení{" "}
                <span className="badge badge-light">{companiesToConfirm}</span>
              </button>
            </Link>
            <Link to="/topics?status=unconfirmed">
              <button
                type="button"
                className={
                  "btn mr-2 " +
                  (topicsToConfirm === 0 ? "btn-success" : "btn-warning")
                }
              >
                Témata ke schválení{" "}
                <span className="badge badge-light">{topicsToConfirm}</span>
              </button>
            </Link>
            <Link to="/student-registrations">
              <button
                type="button"
                className={
                  "btn mr-2 " +
                  (studentToTopicRegistrationsToConfirm === 0
                    ? "btn-success"
                    : "btn-warning")
                }
              >
                Přihlášky ke schválení{" "}
                <span className="badge badge-light">
                  {studentToTopicRegistrationsToConfirm}
                </span>
              </button>
            </Link>
            <Link to="/student-registrations">
              <button
                type="button"
                className={
                  "btn mr-2 " +
                  (finishedTopicsWithoutStudentCredits === 0
                    ? "btn-success"
                    : "btn-warning")
                }
              >
                Nepřidělené kredity{" "}
                <span className="badge badge-light">
                  {finishedTopicsWithoutStudentCredits}
                </span>
              </button>
            </Link>
          </div>
        </Fragment>
      ) : null}
      <h5 id="tabelLabel">Statistiky</h5>
      <hr />
      <AdminStatsTabs />
    </Fragment>
  );
}
