import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const ThemeContext = React.createContext({
  isLoading: false,
  setLoadingStatus: (isLoading) => {},
  errorMessage: "",
  setErrorMessage: (message) => {},
  successMessage: "",
  setSuccessMessage: (message) => {},
  warningMessage: "",
  setWarningMessage: (message) => {},
});

export default ThemeContext;

export function ThemeProvider(props) {
  const [isLoading, setLoading] = useState(false);

  const setLoadingStatus = (isLoading) => {
    setLoading(isLoading);
  };

  const setErrorMessage = (message) => {
    toast.error(message, { autoClose: 5000 });
  };

  const setSuccessMessage = (message) => {
    toast.success(message, { autoClose: 3000 });
  };

  const setWarningMessage = (message) => {
    toast.warn(message, { autoClose: 3000 });
  };

  return (
    <ThemeContext.Provider
      value={{
        isLoading,
        setLoadingStatus,
        setErrorMessage,
        setSuccessMessage,
        setWarningMessage,
      }}
    >
      {props.children}
      <ToastContainer />
    </ThemeContext.Provider>
  );
}
