import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { TopicForm } from "./TopicForm";
import { TopicStudents } from "./TopicStudents";

export function TopicTabs(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [isNewTopic, setIsNewTopic] = useState(false);
  const [isStudentsTabVisible, setIsStudentsTabVisible] = useState(false);
  const [studentsTabTitle, setStudentsTabTitle] = useState(
    "Přihlášení studenti"
  );

  useEffect(function effectFunction() {
    setIsNewTopic(props.match.params.id === "new");
    const tabId =
      props.location.search.indexOf("tabId=") > -1
        ? props.location.search.substr(props.location.search.indexOf("=") + 1)
        : "1";
    setActiveTab(tabId);
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    props.history.push(`?tabId=${tab}`);
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            <h5 id="tabelLabel">Detail tématu</h5>
          </NavLink>
        </NavItem>
        {!isNewTopic && isStudentsTabVisible ? (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              <h5 id="tabelLabel">{studentsTabTitle}</h5>
            </NavLink>
          </NavItem>
        ) : null}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <TopicForm
                topicId={isNewTopic ? "new" : props.match.params.id}
                copyOfId={props.match.params.copyofid}
                setStudentsTabVisibility={setIsStudentsTabVisible}
              />
            </Col>
          </Row>
        </TabPane>
        {!isNewTopic && isStudentsTabVisible ? (
          <TabPane tabId="2">
            <Row className="mt-3">
              <Col sm="12">
                <TopicStudents
                  topicId={props.match.params.id}
                  history={props.history}
                  setTitle={setStudentsTabTitle}
                />
              </Col>
            </Row>
          </TabPane>
        ) : null}
      </TabContent>
    </div>
  );
}
