import React, { useState, useEffect, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import useFetch from "../api-hooks/useFetch";
import { Link, useHistory } from "react-router-dom";
import { TOPIC_TYPES } from "../common/Constants";
import { StudentStatsBoxes } from "./StudentStatsBoxes";
import { dateFormatter } from "../common/GridUtils";

export function StudentDashboard(props) {
  const fetchService = useFetch("api/student-dashboard");
  const history = useHistory();
  const [topics, setTopics] = useState([]);
  const [topicsWork, setTopicsWork] = useState([]);
  const [topicsProject, setTopicsProject] = useState([]);
  const [topicsInternship, setTopicsInternship] = useState([]);
  const [topicsSelectiveProject, setTopicsSelectiveProject] = useState([]);
  const [statsTotals, setStatsTotals] = useState(null);

  useEffect(function effectFunction() {
    async function fetchTopics() {
      const response = await fetchService.get();
      if (!response) {
        return;
      }
      if (Array.isArray(response.topics)) {
        setTopics(response.topics);
        setTopicsWork(getFirstTopicsOfType(response.topics, 1));
        setTopicsProject(getFirstTopicsOfType(response.topics, 2));
        setTopicsInternship(getFirstTopicsOfType(response.topics, 3));
        setTopicsSelectiveProject(getFirstTopicsOfType(response.topics, 4));
      }

      setStatsTotals(response.totals);
    }
    fetchTopics();
  }, []);

  const getFirstTopicsOfType = (topics, typeId) => {
    var firstTopicsOfType = topics
      .filter((t) => t.topicTypeId === typeId)
      .slice(0, 3)
      .map((t) => {
        return t;
      });

    return firstTopicsOfType;
  };

  const getTopicsCountByType = (typeId) => {
    return topics.filter((t) => t.topicTypeId === typeId).length;
  };

  const gridColumns = [
    {
      dataField: "title",
      text: "Název",
    },
    {
      dataField: "companyName",
      text: "Firma",
    },
    {
      dataField: "validTo",
      text: "Platí do",
      classes: "text-nowrap",
      formatter: dateFormatter,
    },
    {
      dataField: "occupancy",
      text: "Obsazenost",
      align: "right",
      headerAlign: "right",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/student-topics?tab=${row.topicTypeId}`);
    },
  };

  return (
    <Fragment>
      <h5 id="tabelLabel">Souhrnné informace</h5>
      <hr />
      <StudentStatsBoxes statsTotals={statsTotals} />
      <h5 id="tabelLabel">Aktuální nabídky</h5>
      <hr />
      <div className="row">
        <div className="col-6">
          <div className="card card-outline card-blue">
            <div className="card-header">
              <Link to={`/student-topics?tab=${TOPIC_TYPES.WORK.id}`}>
                <h3 className="card-title">
                  Nabídky práce ({getTopicsCountByType(TOPIC_TYPES.WORK.id)})
                </h3>
              </Link>
            </div>
            <div className="card-body">
              <BootstrapTable
                keyField="id"
                data={topicsWork}
                columns={gridColumns}
                rowEvents={rowEvents}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card card-outline card-orange">
            <div className="card-header">
              <Link to={`/student-topics?tab=${TOPIC_TYPES.PROJECT.id}`}>
                <h3 className="card-title">
                  Praktické projekty (
                  {getTopicsCountByType(TOPIC_TYPES.PROJECT.id)})
                </h3>
              </Link>
            </div>
            <div className="card-body">
              <BootstrapTable
                keyField="id"
                data={topicsProject}
                columns={gridColumns}
                rowEvents={rowEvents}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="card card-outline card-red">
            <div className="card-header">
              <Link to={`/student-topics?tab=${TOPIC_TYPES.INTERNSHIP.id}`}>
                <h3 className="card-title">
                  Stáže ({topicsInternship.length})
                </h3>
              </Link>
            </div>
            <div className="card-body">
              <BootstrapTable
                keyField="id"
                data={topicsInternship}
                columns={gridColumns}
                rowEvents={rowEvents}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card card-outline card-green">
            <div className="card-header">
              <Link
                to={`/student-topics?tab=${TOPIC_TYPES.SELECTIVE_PROJECT.id}`}
              >
                <h3 className="card-title">
                  Výběrové projekty ({topicsSelectiveProject.length})
                </h3>
              </Link>
            </div>
            <div className="card-body">
              <BootstrapTable
                keyField="id"
                data={topicsSelectiveProject}
                columns={gridColumns}
                rowEvents={rowEvents}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
