import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import useFetch from "../api-hooks/useFetch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  rowClasses,
  confirmedColumnFormatter,
  attributesColumnFormatter,
  dateFormatter,
} from "../common/GridUtils";
import UserContext from "../contexts/UserContext";
import { StudentRegistrationDeleteModal } from "../student-registrations/StudentRegistrationDeleteModal";

export function TopicStudents(props) {
  const fetchService = useFetch("api/topic-students");
  const userContext = useContext(UserContext);
  const [students, setStudents] = useState([]);
  const [currentRowUserId, setCurrentRowUserId] = useState(null);
  const [currentRowTopicId, setCurrentRowTopicId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { SearchBar } = Search;

  useEffect(function effectFunction() {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    const response = await fetchService.get(props.topicId);
    if (Array.isArray(response)) {
      setStudents(response);
      props.setTitle(`Přihlášení studenti (${response.length})`);
    }
  };

  const deleteStudent = (e, userId, topicId) => {
    e.preventDefault();
    setCurrentRowUserId(userId);
    setCurrentRowTopicId(topicId);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = async () => {
    setShowDeleteModal(false);
    await fetchStudents();
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link
              to={`/student-registration-details/${row.userId}/${row.topicId}`}
            >
              <FontAwesomeIcon icon="eye" title="Prohlížet záznam" />
            </Link>
            <Link
              to={`/student-topic/delete/${row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                deleteStudent(e, row.userId, row.topicId);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" title="Smazat registraci" />
            </Link>
          </Fragment>
        ) : null}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "firstName",
      text: "Jméno",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Příjmení",
      sort: true,
    },
    {
      dataField: "createdWhen",
      text: "Vytvořeno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "updatedWhen",
      text: "Akualizováno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "confirmedAtUniversity",
      text: "Přihláška schválená UHK",
      sort: true,
      formatter: confirmedColumnFormatter,
    },
    {
      dataField: "confirmedAtCompany",
      text: "Přihláška schválená firmou",
      sort: true,
      formatter: confirmedColumnFormatter,
    },
    {
      dataField: "attributes",
      text: "Atributy",
      hidden: !userContext.user?.isAdmin,
      formatter: attributesColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
    {
      dataField: "userId",
      hidden: true,
    },
    {
      dataField: "topicId",
      hidden: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "title",
      order: "desc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      props.history.push(
        `/student-registration-details/${row.userId}/${row.topicId}`
      );
    },
  };

  return (
    <Fragment>
      <ToolkitProvider
        keyField="userId"
        data={students}
        columns={gridColumns}
        search
      >
        {(toolkitprops) => (
          <div className="col-12">
            <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />

            <div className="card card-outline">
              <div className="card-header">
                <BootstrapTable
                  {...toolkitprops.baseProps}
                  keyField="userId"
                  data={students}
                  columns={gridColumns}
                  pagination={paginationFactory({ sizePerPage: 25 })}
                  rowEvents={rowEvents}
                  defaultSorted={defaultSorted}
                  rowClasses={rowClasses}
                  bordered={false}
                  noDataIndication={
                    <div className="text-center">Žádné záznamy</div>
                  }
                  striped
                  hover
                  classes="table-sm"
                />
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>

      <StudentRegistrationDeleteModal
        isOpen={showDeleteModal}
        userId={currentRowUserId}
        topicId={currentRowTopicId}
        onClose={onDeleteModalClose}
        onDeleted={onDeleted}
      />
    </Fragment>
  );
}
