import React, { Fragment } from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export function MenuItemsAdmin() {
  return (
    <Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/admin-dashboard">
          <FontAwesomeIcon icon="tachometer-alt" /> Úvod
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/topics">
          <FontAwesomeIcon icon="stream" /> Témata
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-registrations">
          <FontAwesomeIcon icon="sign-in-alt" /> Přihlášky
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/student-requests">
          <FontAwesomeIcon icon="user-graduate" /> Poptávky
        </NavLink>
      </NavItem>

      <UncontrolledDropdown>
        <DropdownToggle nav caret className="text-light nav-link">
          <FontAwesomeIcon icon="tools" /> Admin
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <Link to="/users">
              <FontAwesomeIcon icon="users" /> Uživatelé
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/companies">
              <FontAwesomeIcon icon="industry" /> Firmy
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/email-templates">
              <FontAwesomeIcon icon="envelope" /> Šablony emailů
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Fragment>
  );
}
