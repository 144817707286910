// https://github.com/aleckan53/react-context-playground/blob/master/src/context/ThemeContext.js

import React, { createContext } from "react";
import authService from "../api-authorization/AuthorizeService";
import { UserRoles } from "../api-authorization/ApiAuthorizationConstants";

const UserContext = createContext({
  user: {
    name: "",
    role: null,
    isAuthenticated: false,
  },
  firstDasboardRedirectDone: false,
  setUser: () => {},
  setFirstDasboardRedirectDone: () => {},
});

export default UserContext;

export class UserProvider extends React.Component {
  setUser = (newUser) => {
    this.setState({ user: newUser });
  };

  setFirstDasboardRedirectDone = (done) => {
    this.setState({ firstDasboardRedirectDone: done });
  };

  state = {
    user: {
      name: "",
      role: null,
    },
    isAuthenticated: false,
    isPopulated: false,
    setUser: this.setUser,
    setFirstDasboardRedirectDone: this.setFirstDasboardRedirectDone,
  };

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);

    this.setState({
      user: {
        id: user && user.sub,
        name: user && user.name,
        role: user && user.role,
        isAuthenticated: isAuthenticated,
        isAdmin: user && user.role && user.role === UserRoles.Admin,
        isGuarantor: user && user.role && user.role === UserRoles.Guarantor,
        isStudent: user && user.role && user.role === UserRoles.Student,
        isCompanyManager:
          user && user.role && user.role === UserRoles.CompanyManager,
        isCompanyEmployee:
          user && user.role && user.role === UserRoles.CompanyEmployee,
        isCompanyUser:
          user &&
          user.role &&
          (user.role === UserRoles.CompanyEmployee ||
            user.role === UserRoles.CompanyManager),
        isUniversityUser:
          user &&
          user.role &&
          (user.role === UserRoles.Admin || user.role === UserRoles.Guarantor),
      },
      isAuthenticated: !!user,
      isPopulated: true,
      // userName: user && user.name
    });
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

//export const UserConsumer = UserContext.Consumer;
