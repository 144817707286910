import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { CompanyForm } from "./CompanyForm";
import { CompanyEmployees } from "./CompanyEmployees";
import { CompanyTopics } from "./CompanyTopics";
import { useHistory } from "react-router-dom";

export function CompanyTabs(props) {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    const tabId =
      props.location.search.indexOf("tabId=") > -1
        ? props.location.search.substr(props.location.search.indexOf("=") + 1)
        : "1";
    setActiveTab(tabId);
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    history.push(`?tabId=${tab}`);
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            <h5 id="tabelLabel">Detail firmy</h5>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            <h5 id="tabelLabel">Zaměstnanci</h5>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggle("3");
            }}
          >
            <h5 id="tabelLabel">Témata</h5>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <CompanyForm companyId={props.match.params.id} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row className="mt-3">
            <Col sm="12">
              <CompanyEmployees companyId={props.match.params.id} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row className="mt-3">
            <Col sm="12">
              <CompanyTopics companyId={props.match.params.id} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
