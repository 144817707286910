import React, { useState, useEffect, Fragment } from "react";
import useFetch from "../api-hooks/useFetch";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Button, Label } from "reactstrap";
import { BackButton } from "../common/BackButton";
import { AuditInfo } from "../common/AuditInfo";
import { useHistory } from "react-router-dom";

export function EmailTemplateForm(props) {
  const fetchService = useFetch("api/email-template");
  const history = useHistory();
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [description, setDescription] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  useEffect(function effectFunction() {
    async function fetchEmailTemplate() {
      const data = await fetchService.get(props.match.params.id);
      if (data) {
        if (data.id) {
          setEmailTemplateData(data);
        }
      }
    }
    fetchEmailTemplate();
  }, []);

  const setEmailTemplateData = (emailTemplate) => {
    setEmailTemplate(emailTemplate);
    setDescription(emailTemplate.description);
    setEmailSubject(emailTemplate.emailSubject);
    setEmailBody(emailTemplate.emailBody);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      id: parseInt(props.match.params.id),
      code: emailTemplate.code,
      description,
      emailSubject,
      emailBody,
    };

    const response = await fetchService.put(props.match.params.id, body);

    if (response?.id) {
      setTimeout(function () {
        history.push("/email-templates");
      }, 3000);
    }
  };

  return (
    <div className="card card-outline form-container">
      <div className="card-header">
        <h5 id="tabelLabel">Detail šablony emailu</h5>
        <hr />
        <AvForm onValidSubmit={handleSubmit}>
          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-0">
              <Label htmlFor="title">Kód</Label>
              <AvField
                type="text"
                name="code"
                value={emailTemplate?.code}
                readOnly={true}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-12 pr-2 mb-0">
              <Label htmlFor="description">Popis</Label>
              <AvField
                type="textarea"
                rows={1}
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-0">
              <Label htmlFor="title">Subjekt emailu</Label>
              <AvField
                type="text"
                name="emailSubject"
                value={emailSubject}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Subjekt je povinná položka",
                  },
                }}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-12 pr-2 mb-0">
              <Label htmlFor="description">Tělo emailu</Label>
              <AvField
                type="textarea"
                rows={6}
                name="emailBody"
                value={emailBody}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Zpráva je povinná položka",
                  },
                }}
                onChange={(e) => setEmailBody(e.target.value)}
              />
            </AvGroup>
          </div>

          <div className="row no-gutters">
            <AvGroup className="col-6 pr-2 mb-0">
              <Label htmlFor="title">Použitelné proměnné</Label>
              <AvField
                type="text"
                name="code"
                value={emailTemplate?.placeholders}
                readOnly={true}
              />
            </AvGroup>
          </div>

          <Button className="btn btn-primary mr-2">Uložit</Button>
          <BackButton />
          <AuditInfo auditData={emailTemplate} />
        </AvForm>
      </div>
    </div>
  );
}
