import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import useFetch from "../api-hooks/useFetch";
import {
  rowClasses,
  attributesColumnFormatter,
  dateFormatter,
} from "../common/GridUtils";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { UserDeleteModal } from "./UserDeleteModal";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import UserContext from "../contexts/UserContext";

export function Users(props) {
  const fetchService = useFetch("api/user");
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { SearchBar } = Search;

  useEffect(function effectFunction() {
    if (!userContext.user?.isAdmin) {
      history.push("/");
    }

    fetchUsers();
    fetchRoles();
  }, []);

  useEffect(
    function effectFunction2() {
      filterUsers();
    },
    [showDeleted, users, selectedRole]
  );

  const fetchUsers = async () => {
    const response = await fetchService.get();
    if (Array.isArray(response)) {
      setUsers(response);
    }
  };

  const fetchRoles = async () => {
    const response = await fetchService.get("user-roles");
    if (Array.isArray(response)) {
      setRoles(response);
    }
  };

  const deleteUser = (e, id) => {
    e.preventDefault();
    setCurrentRowId(id);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = async () => {
    setShowDeleteModal(false);
    await fetchUsers();
  };

  const filterUsers = () => {
    setFilteredUsers(
      users.filter(
        (u) =>
          (u.deleted === false || u.deleted === showDeleted) &&
          (selectedRole === "" || u.roleName === selectedRole)
      )
    );
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/users/${row.id}`}>
              <FontAwesomeIcon icon="edit" title="Editovat záznam" />
            </Link>
            <Link
              to={`/users/delete/${row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                deleteUser(e, row.id);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" title="Smazat záznam" />
            </Link>
          </Fragment>
        ) : (
          <Link to={`/users/${row.id}`}>
            <FontAwesomeIcon icon="eye" title="Prohlížet záznam" />
          </Link>
        )}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "lastName",
      text: "Příjmení",
      sort: true,
    },
    {
      dataField: "firstName",
      text: "Jméno",
      sort: true,
    },
    {
      dataField: "username",
      text: "Přihlašovací jméno",
      sort: true,
    },
    {
      dataField: "roleName",
      text: "Role",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Firma",
      sort: true,
    },
    {
      dataField: "createdWhen",
      text: "Vytvořeno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "updatedWhen",
      text: "Akualizováno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "attributes",
      text: "Atributy",
      hidden: !userContext.user?.isAdmin,
      formatter: attributesColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "lastName",
      order: "asc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/users/${row.id}`);
    },
  };

  return (
    <ToolkitProvider keyField="id" data={users} columns={gridColumns} search>
      {(toolkitprops) => (
        <Fragment>
          <h5 id="tabelLabel">Uživatelé</h5>
          <hr />
          <div className="row">
            <div className="col-2">
              <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />
            </div>

            <div className="col-4">
              <div className="row">
                <div className="col-4 pt-1 text-right">
                  <span>Role</span>
                </div>
                <div className="col-8">
                  <AvForm>
                    <AvField
                      type="select"
                      name="role"
                      onChange={(e) => setSelectedRole(e.target.value)}
                      value={selectedRole}
                      defaultValue=""
                    >
                      <option value="">Všechny</option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </AvField>
                  </AvForm>
                </div>
              </div>
            </div>

            <div className="custom-control custom-checkbox pt-2 col-4 text-right">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showDeleted"
                name="showDeleted"
                checked={showDeleted}
                onChange={(e) => setShowDeleted(e.target.checked)}
              />
              <label
                className="custom-control-label font-weight-normal"
                htmlFor="showDeleted"
              >
                Zobrazit smazané
              </label>
            </div>

            <div className="col-2 text-right">
              <Link
                to="/users/new"
                className="btn btn-primary mb-2 float-right"
              >
                <FontAwesomeIcon icon="plus" /> Nový uživatel
              </Link>
            </div>
          </div>

          <div className="card card-outline">
            <div className="card-header">
              <BootstrapTable
                {...toolkitprops.baseProps}
                keyField="id"
                data={filteredUsers}
                columns={gridColumns}
                pagination={paginationFactory({ sizePerPage: 25 })}
                filter={filterFactory()}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                rowClasses={rowClasses}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>

          <UserDeleteModal
            isOpen={showDeleteModal}
            id={currentRowId}
            onClose={onDeleteModalClose}
            onDeleted={onDeleted}
          />
        </Fragment>
      )}
    </ToolkitProvider>
  );
}
