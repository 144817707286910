import React, { Fragment } from "react";
import { NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export function MenuItemsPublic() {
  return (
    <Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/">
          <FontAwesomeIcon icon="home" /> Úvod
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-light" to="/register-company">
          <FontAwesomeIcon icon="industry" /> Registrace pro firmy
        </NavLink>
      </NavItem>
    </Fragment>
  );
}
