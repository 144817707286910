import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from "../api-hooks/useFetch";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { rowClasses, dateFormatter } from "../common/GridUtils";
import { StudentRequestDeleteModal } from "./StudentRequestDeleteModal";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import UserContext from "../contexts/UserContext";

export function StudentRequests(props) {
  const fetchService = useFetch("api/student-request");
  const userContext = useContext(UserContext);
  const [studentRequests, setStudentRequests] = useState([]);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { SearchBar } = Search;

  useEffect(function effectFunction() {
    fetchStudentRequests();
  }, []);

  const fetchStudentRequests = async () => {
    const studentRequests = await fetchService.get();
    if (!studentRequests) {
      return;
    }
    if (Array.isArray(studentRequests)) {
      setStudentRequests(studentRequests);
    }
  };

  const deleteStudentRequest = (e, id) => {
    e.preventDefault();
    setCurrentRowId(id);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = () => {
    setShowDeleteModal(false);
    fetchStudentRequests();
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/student-requests/${row.id}`}>
              <FontAwesomeIcon icon="eye" title="Prohlížet záznam" />
            </Link>
            {userContext.user && userContext.user.isAdmin ? (
              <Link
                to={`/student-request/delete/${row.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteStudentRequest(e, row.id);
                }}
              >
                <FontAwesomeIcon icon="trash-alt" title="Smazat záznam" />
              </Link>
            ) : null}
          </Fragment>
        ) : null}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "topicTypeName",
      text: "Typ tématu",
      sort: true,
    },
    {
      dataField: "title",
      text: "Název",
      sort: true,
    },
    {
      dataField: "studentEmail",
      text: "Student",
      sort: true,
      hidden: userContext.user?.isStudent,
    },
    {
      dataField: "createdWhen",
      text: "Vytvořeno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "validTo",
      text: "Platí do",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const tableOptions = {
    noDataText: "Žádná data",
  };

  const defaultSorted = [
    {
      dataField: "validTo",
      order: "desc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/student-requests/${row.id}`);
    },
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={studentRequests}
      columns={gridColumns}
      search
    >
      {(toolkitprops) => (
        <Fragment>
          {userContext.user.isStudent ? (
            <h5 id="tabelLabel">Moje poptávky</h5>
          ) : (
            <h5 id="tabelLabel">Poptávky studentů</h5>
          )}

          <hr />
          <div>
            <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />

            {userContext.user && userContext.user.isStudent ? (
              <Link
                to="/student-requests/new"
                className="btn btn-primary mb-2 float-right"
              >
                <FontAwesomeIcon icon="plus" /> Nová poptávka
              </Link>
            ) : null}
          </div>

          <div className="card card-outline">
            <div className="card-header">
              <BootstrapTable
                {...toolkitprops.baseProps}
                keyField="id"
                data={studentRequests}
                columns={gridColumns}
                options={tableOptions}
                pagination={paginationFactory({ sizePerPage: 25 })}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                rowClasses={rowClasses}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>

          <StudentRequestDeleteModal
            isOpen={showDeleteModal}
            id={currentRowId}
            onClose={onDeleteModalClose}
            onDeleted={onDeleted}
          />
        </Fragment>
      )}
    </ToolkitProvider>
  );
}
