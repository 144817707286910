import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./menu/NavMenu";
import { AppFooter } from "./AppFooter";

export function Layout(props) {
  return (
    <div>
      <Fragment>
        <NavMenu />
        <Container className="mb-5">{props.children}</Container>
        <AppFooter />
      </Fragment>
    </div>
  );
}
