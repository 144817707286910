import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import useFetch from "../api-hooks/useFetch";
import queryString from "query-string";
import {
  rowClasses,
  confirmedColumnFormatter,
  attributesColumnFormatter,
  dateFormatter,
} from "../common/GridUtils";
import { COMPANY_STATUSES } from "../common/Constants";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CompanyDeleteModal } from "./CompanyDeleteModal";
import UserContext from "../contexts/UserContext";

export function Companies(props) {
  const fetchService = useFetch("api/company");
  const userContext = useContext(UserContext);
  const params = queryString.parse(props.location.search);
  const queryStatusId = params.status || COMPANY_STATUSES.ALL.id;

  const [selectedStatusId, setSelectedStatusId] = useState(queryStatusId);
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { SearchBar } = Search;

  useEffect(function effectFunction() {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    const response = await fetchService.get();
    if (Array.isArray(response)) {
      setCompanies(response);
    }
  };

  useEffect(
    function effectFunction() {
      changeStatus(selectedStatusId);
    },
    [companies, showDeleted]
  );

  const changeStatus = (statusId) => {
    setSelectedStatusId(statusId);
    switch (statusId) {
      case COMPANY_STATUSES.ALL.id:
        setFilteredCompanies(
          companies.filter(
            (c) => c.deleted === false || c.deleted === showDeleted
          )
        );
        break;
      case COMPANY_STATUSES.CONFIRMED.id:
        setFilteredCompanies(
          companies.filter(
            (c) =>
              c.confirmed && (c.deleted === false || c.deleted === showDeleted)
          )
        );
        break;
      case COMPANY_STATUSES.UNCONFIRMED.id:
        setFilteredCompanies(
          companies.filter(
            (c) =>
              !c.confirmed && (c.deleted === false || c.deleted === showDeleted)
          )
        );
        break;
      default:
        break;
    }
  };

  const deleteCompany = (e, id) => {
    e.preventDefault();
    setCurrentRowId(id);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = async () => {
    setShowDeleteModal(false);
    await fetchCompanies();
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/companies/${row.id}`}>
              <FontAwesomeIcon icon="edit" title="Editovat záznam" />
            </Link>
            <Link
              to={`/companies/delete/${row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                deleteCompany(e, row.id);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" title="Smazat záznam" />
            </Link>
          </Fragment>
        ) : (
          <Link to={`/companies/${row.id}`}>
            <FontAwesomeIcon icon="eye" title="Prohlížet záznam" />
          </Link>
        )}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "name",
      text: "Název",
      sort: true,
    },
    {
      dataField: "town",
      text: "Město",
      sort: true,
    },
    {
      dataField: "numberOfTopics",
      text: "Počet témat",
      sort: true,
    },
    {
      dataField: "createdWhen",
      text: "Vytvořeno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "updatedWhen",
      text: "Aktualizováno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "confirmed",
      text: "Schváleno",
      sort: true,
      formatter: confirmedColumnFormatter,
    },
    {
      dataField: "attributes",
      text: "Atributy",
      hidden: !userContext.user?.isAdmin,
      formatter: attributesColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/companies/${row.id}`);
    },
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={filteredCompanies}
      columns={gridColumns}
      search
    >
      {(toolkitprops) => (
        <Fragment>
          <h5 id="tabelLabel">Firmy</h5>
          <hr />
          <div className="row">
            <div className="col-2">
              <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />
            </div>
            <div className="col-6 pl-4 pt-2">
              {Object.keys(COMPANY_STATUSES).map((key, i) => (
                <div
                  className="custom-control custom-radio custom-control-inline"
                  key={i}
                >
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={"status-" + COMPANY_STATUSES[key].id}
                    name="status"
                    value={COMPANY_STATUSES[key].id}
                    checked={selectedStatusId === COMPANY_STATUSES[key].id}
                    onChange={(e) => changeStatus(e.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"status-" + COMPANY_STATUSES[key].id}
                  >
                    {COMPANY_STATUSES[key].label}
                  </label>
                </div>
              ))}
            </div>

            <div className="col-2 pt-2 custom-control custom-checkbox text-right">
              {userContext.user.isAdmin ? (
                <Fragment>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showDeleted"
                    name="showDeleted"
                    checked={showDeleted}
                    onChange={(e) => setShowDeleted(e.target.checked)}
                  />
                  <label
                    className="custom-control-label font-weight-normal"
                    htmlFor="showDeleted"
                  >
                    Zobrazit smazané
                  </label>
                </Fragment>
              ) : null}
            </div>

            <div className="col-2 text-right">
              <Link to="/register-company" className="btn btn-primary mb-2">
                <FontAwesomeIcon icon="plus" /> Nová firma
              </Link>
            </div>
          </div>

          <div className="card card-outline">
            <div className="card-header">
              <BootstrapTable
                {...toolkitprops.baseProps}
                keyField="id"
                data={filteredCompanies}
                columns={gridColumns}
                pagination={paginationFactory({ sizePerPage: 25 })}
                filter={filterFactory()}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                rowClasses={rowClasses}
                bordered={false}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>

          <CompanyDeleteModal
            isOpen={showDeleteModal}
            id={currentRowId}
            onClose={onDeleteModalClose}
            onDeleted={onDeleted}
          />
        </Fragment>
      )}
    </ToolkitProvider>
  );
}
