import { useContext } from "react";
import { useHistory } from "react-router-dom";
import ThemeContext from "../contexts/ThemeContext";
import authService from "../api-authorization/AuthorizeService";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";

const useFetch = (endpoint) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();

  const defaultHeader = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const customFetch = async (
    url,
    method = "GET",
    body = false,
    successMessage = false,
    headers = defaultHeader
  ) => {
    themeContext.setLoadingStatus(true);
    const token = await authService.getAccessToken();

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const options = {
      method,
      headers,
    };

    if (body) options.body = JSON.stringify(body);

    try {
      const response = await fetch(url, options);
      themeContext.setLoadingStatus(false);

      if (response.status === 401) {
        history.push(`${ApplicationPaths.Login}`);
        return null;
      } else if (response.status === 403) {
        themeContext.setErrorMessage(`Přístup zamítnut`);
        return null;
      }

      const responseData = await response.json();

      // kvůli vyskakování přihlašovacího popupu prohlížeče při chybě 401 vrací API 200 a text "NotAuthenticated"
      if (response.status === 200 && responseData === "NotAuthenticated") {
        history.push(`${ApplicationPaths.Login}`);
        return null;
      }

      if (!response.ok) {
        console.log(`Error Response ${response.status}`, responseData);

        if (responseData.errors) {
          for (var fieldName in responseData.errors) {
            themeContext.setErrorMessage(
              `Chyba validace: ${responseData.errors[fieldName]}`
            );
          }
        } else {
          themeContext.setErrorMessage(
            `Chyba: ${responseData || response.statusText}`
          );
        }

        return null;
      }

      if (method === "GET" && successMessage) {
        themeContext.setSuccessMessage(successMessage);
      }

      if (method === "POST") {
        themeContext.setSuccessMessage(
          successMessage ? successMessage : "Záznam byl úspěšně vytvořen."
        );
      } else if (method === "PUT") {
        themeContext.setSuccessMessage(
          successMessage ? successMessage : "Záznam byl úspěšně aktualizován."
        );
      } else if (method === "DELETE") {
        themeContext.setSuccessMessage(
          successMessage ? successMessage : "Záznam byl úspěšně odstraněn."
        );
      }

      return responseData;
    } catch (err) {
      console.log(err);
      themeContext.setLoadingStatus(false);
      themeContext.setErrorMessage(err.toString());
    }
  };

  const get = async (id, successMessage = false) => {
    const url = `${endpoint}${id ? `/${id}` : ""}`;

    return await customFetch(url, "GET", false, successMessage);
  };

  const post = async (
    body = false,
    realtiveUrlPath,
    successMessage = false
  ) => {
    const url = `${endpoint}${realtiveUrlPath ? `/${realtiveUrlPath}` : ""}`;
    if (!body) {
      themeContext.setErrorMessage("Chyba POST requestu: chybí tělo zprávy");
    }
    return await customFetch(url, "POST", body, successMessage);
  };

  const put = async (id = false, body = false, successMessage = false) => {
    if (!id || !body) {
      themeContext.setErrorMessage(
        "Chyba PUT requestu: chybí id nebo tělo zprávy"
      );
    }
    const url = `${endpoint}/${id}`;
    return await customFetch(url, "PUT", body, successMessage);
  };

  const del = async (id = false, successMessage = false) => {
    if (!id) {
      themeContext.setErrorMessage("Chyba DELETE requestu: chybí parametr id");
    }
    const url = `${endpoint}/${id}`;
    return await customFetch(url, "DELETE", successMessage);
  };

  const download = async (id, successMessage = false) => {
    const url = `${endpoint}${id ? `/${id}` : ""}`;

    const headers = {
      Accept: "application/octet-stream",
      "Content-Type": "application/json",
    };

    const token = await authService.getAccessToken();

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const options = {
      method: "GET",
      headers,
    };

    const response = await fetch(url, options);

    return response.blob();
  };

  return {
    get,
    post,
    put,
    del,
    download,
  };
};
export default useFetch;
