import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from "../api-hooks/useFetch";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { rowClasses, dateFormatter } from "../common/GridUtils";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

export function EmailTemplates(props) {
  const fetchService = useFetch("api/email-template");
  const [emailTemplates, setEmailTemplates] = useState([]);
  const { SearchBar } = Search;

  const fetchEmailTemplates = async () => {
    const emailTemplates = await fetchService.get();
    if (!emailTemplates) {
      return;
    }
    if (Array.isArray(emailTemplates)) {
      setEmailTemplates(emailTemplates);
    }
  };

  useEffect(
    function effectFunction() {
      fetchEmailTemplates();
    },
    [fetchEmailTemplates]
  );

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/email-template/${row.id}`}>
              <FontAwesomeIcon icon="edit" title="Upravit záznam" />
            </Link>
          </Fragment>
        ) : null}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "code",
      text: "Kód",
      sort: true,
    },
    {
      dataField: "emailSubject",
      text: "Subjekt",
      sort: true,
    },
    {
      dataField: "createdWhen",
      text: "Vytvořeno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "updatedWhen",
      text: "Aktualizováno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/email-templates/${row.id}`);
    },
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={emailTemplates}
      columns={gridColumns}
      search
    >
      {(toolkitprops) => (
        <Fragment>
          <h5 id="tabelLabel">Šablony emailů</h5>
          <hr />
          <div>
            <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />
          </div>

          <div className="card card-outline">
            <div className="card-header">
              <BootstrapTable
                {...toolkitprops.baseProps}
                keyField="id"
                data={emailTemplates}
                columns={gridColumns}
                pagination={paginationFactory({ sizePerPage: 25 })}
                rowEvents={rowEvents}
                defaultSorted={defaultSorted}
                rowClasses={rowClasses}
                noDataIndication={
                  <div className="text-center">Žádné záznamy</div>
                }
                bordered={false}
                striped
                hover
                classes="table-sm"
              />
            </div>
          </div>
        </Fragment>
      )}
    </ToolkitProvider>
  );
}
