import React, { useState, useEffect, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useFetch from "../api-hooks/useFetch";

export function TopicDeleteModal(props) {
  const fetchService = useFetch("api/topic");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    setDeleteModalOpen(props.isOpen);
  }, [props.isOpen]);

  const toggleModal = () => setDeleteModalOpen(!isDeleteModalOpen);

  const confirmDelete = async () => {
    toggleModal();
    await fetchService.del(parseInt(props.id));
    props.onDeleted();
  };

  const closeModal = () => {
    toggleModal();
    props.onClose();
  };

  return (
    <Fragment>
      <Modal
        isOpen={isDeleteModalOpen}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal}>Potvrzení</ModalHeader>
        <ModalBody>Opravdu chcete smazat toto téma?</ModalBody>
        <ModalFooter>
          <Button className="btn btn-primary" onClick={confirmDelete}>
            Ano
          </Button>{" "}
          <Button className="btn btn-secondary" onClick={closeModal}>
            Ne
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
