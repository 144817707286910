import React, { useState, useEffect, Fragment } from "react";

export function AuditInfo(props) {
  const [createdWhen, setCreatedWhen] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedWhen, setUpdatedWhen] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [deletedWhen, setDeletedWhen] = useState("");
  const [deletedBy, setDeletedBy] = useState("");
  const [confirmedWhen, setConfirmedWhen] = useState("");
  const [confirmedBy, setConfirmedBy] = useState("");

  useEffect(function effectFunction() {
    if (props.auditData) {
      setCreatedWhen(new Date(props.auditData.createdWhen).toLocaleString());
      setCreatedBy(props.auditData.createdBy);
      setUpdatedWhen(
        props.auditData.updatedWhen
          ? new Date(props.auditData.updatedWhen).toLocaleString()
          : ""
      );
      setUpdatedBy(props.auditData.updatedBy);
      setDeletedWhen(
        props.auditData.deletedWhen
          ? new Date(props.auditData.deletedWhen).toLocaleString()
          : ""
      );
      setDeletedBy(props.auditData.deletedBy);
      setConfirmedWhen(
        props.auditData.confirmedWhen
          ? new Date(props.auditData.confirmedWhen).toLocaleString()
          : ""
      );
      setConfirmedBy(props.auditData.confirmedBy);
    }
  });

  return (
    <Fragment>
      {props.auditData ? (
        <div className="card audit-info text-secondary mt-4 mb-2">
          <div className="card-body">
            <h5 className="card-title mb-2">Auditní informace</h5>
            <p className="card-text">
              Vytvořeno {createdWhen} uživatelem {createdBy}.
              {confirmedWhen ? (
                <Fragment>
                  <br />
                  <span>
                    Schváleno {confirmedWhen} uživatelem {confirmedBy}.
                  </span>
                </Fragment>
              ) : null}
              {updatedWhen ? (
                <Fragment>
                  <br />
                  <span>
                    Naposledy aktualizováno {updatedWhen} uživatelem {updatedBy}
                    .
                  </span>
                </Fragment>
              ) : null}
              {deletedWhen ? (
                <Fragment>
                  <br />
                  <span>
                    Smazáno {deletedWhen} uživatelem {deletedBy}.
                  </span>
                </Fragment>
              ) : null}
            </p>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}
