import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import useFetch from "../api-hooks/useFetch";
import {
  rowClasses,
  attributesColumnFormatter,
  dateFormatter,
} from "../common/GridUtils";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import UserContext from "../contexts/UserContext";
import { UserDeleteModal } from "../users/UserDeleteModal";

export function CompanyEmployees(props) {
  const fetchService = useFetch("api/company-employees");
  const userContext = useContext(UserContext);
  const [employees, setEmployees] = useState([]);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { SearchBar } = Search;

  useEffect(
    function effectFunction() {
      fetchEmployees();
    },
    [props.companyId]
  );

  const fetchEmployees = async () => {
    if (props.companyId) {
      const response = await fetchService.get(props.companyId);
      if (Array.isArray(response)) {
        setEmployees(response);
      }
    }
  };

  const deleteEmployee = (e, id) => {
    e.preventDefault();
    setCurrentRowId(id);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleted = async () => {
    setShowDeleteModal(false);
    await fetchEmployees();
  };

  const actionsColumnFormatter = (cell, row, rowIndex) => {
    return (
      <div className="grid-actions">
        {!row.deleted ? (
          <Fragment>
            <Link to={`/companies/${row.id}`}>
              <FontAwesomeIcon icon="edit" title="Editovat záznam" />
            </Link>
            <Link
              to={`/companies/delete/${row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                deleteEmployee(e, row.id);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" title="Smazat záznam" />
            </Link>
          </Fragment>
        ) : null}
      </div>
    );
  };

  const gridColumns = [
    {
      dataField: "firstName",
      text: "Jméno",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Příjmení",
      sort: true,
    },
    {
      dataField: "roleName",
      text: "Role",
      sort: true,
    },
    {
      dataField: "numberOfTopics",
      text: "Počet témat",
      sort: true,
    },
    {
      dataField: "createdWhen",
      text: "Vytvořeno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "updatedWhen",
      text: "Akualizováno",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "attributes",
      text: "Atributy",
      hidden: !userContext.user?.isAdmin,
      formatter: attributesColumnFormatter,
    },
    {
      dataField: "link",
      text: "Akce",
      formatter: actionsColumnFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "title",
      order: "desc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/company-employees/${row.id}`);
    },
  };

  return (
    <Fragment>
      <ToolkitProvider
        keyField="id"
        data={employees}
        columns={gridColumns}
        search
      >
        {(toolkitprops) => (
          <div className="col-12">
            <SearchBar placeholder={"Hledat"} {...toolkitprops.searchProps} />
            <Link
              to={`/users/new?companyId=${props.companyId}`}
              className="btn btn-primary mb-2 float-right"
            >
              <FontAwesomeIcon icon="plus" /> Nový zaměstnanec
            </Link>

            <div className="card card-outline">
              <div className="card-header">
                <BootstrapTable
                  {...toolkitprops.baseProps}
                  keyField="id"
                  data={employees}
                  columns={gridColumns}
                  pagination={paginationFactory({ sizePerPage: 25 })}
                  rowEvents={rowEvents}
                  defaultSorted={defaultSorted}
                  rowClasses={rowClasses}
                  noDataIndication={
                    <div className="text-center">Žádné záznamy</div>
                  }
                  bordered={false}
                  striped
                  hover
                  classes="table-sm"
                />
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>

      <UserDeleteModal
        isOpen={showDeleteModal}
        id={currentRowId}
        onClose={onDeleteModalClose}
        onDeleted={onDeleted}
      />
    </Fragment>
  );
}
